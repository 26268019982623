
.page-header-title h5 {
  font-weight: bolder !important;
  color: #164a90 !important;
}
/** Tables */
/* .table-fix-height {
  max-height: 321px !important;
} */
.table-financial {
  border: 1px solid #000105 !important;
  font-size: 11.5px !important;
}
.table-styling.table-dark {
  background-color: #323645;
  border: 1px solid #000105;
}
.table-financial th {
  color: #fff !important;
  text-align: center;
}
.table-financial td, .table-financial th {
  border-color: #9e9e9e !important;
}
.table-financial th {
  border-bottom: 2px  #9e9e9e !important;
}
.table-financial .table-small-font {
  font-size: 10px;
}
.table-financial .cell-dark {
  color: rgb(255, 255, 255) !important;
}
.table-financial .cell-white {
  background-color: #fff;
  color: black !important;
}
.table-financial .cell-green {
  background-color: #39b54a;
  color: black !important;
}
.table-financial .cell-red {
  background-color: #f43939;
  color: black !important;
}

.table-financial .value-top{
  vertical-align: top;
}

.card-green h4 {
  color: #39b54a !important;
}
.card-red h4{
  color: #f43939 !important;
}
.card-yellow h4 {
  color: #d4c006 !important;
}
.table-financial .right-align-text {
  text-align: right;
}


.prev-month-value > p {
  font-size: 11px !important;
}
.prev-month-value > span {
  font-size: 12px !important;
}
.prev-month-value > span svg {
  width: 11px !important;
  height: 11px !important;
}
.prev-month-value > p {
  margin-bottom: 0px;
}

.chart-container .highcharts-container {
  height: 136px !important;
  /* margin-top: -10px; */
}

.apexcharts-legend > div:first-child {
  margin-right: '280px';
}

.header-logo> img {
  max-height: 92px !important;
}

/** Year and month filter */
.rs-slider {
  position: relative;
  margin-left: 15px;
}

.rs-slider-disabled {
  opacity: 1 !important;
  cursor: not-allowed;
}

 .rs-slider-mark {
  position: absolute;
  top: 15px;
  left: inherit;
  white-space: nowrap;
  color: #000 !important;
  font-size: smaller;
}

.rs-slider-bar {
  height: 6px;
  border-radius: 3px;
  background-color: #000000 !important;
  cursor: pointer;
}

.rs-slider-bar:hover {
  background-color: #000000 !important;
}

.rs-slider-graduator > ol > li.rs-slider-pass::before {
    border-color: #000000 !important;
}

.rs-slider-graduator > ol > li:last-child::after, .rs-slider-graduator > ol > li::before {
  content: '';
  display: block;
  position: absolute;
  width: 5px !important;
  height: 13px !important;
  background-color: #000 !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 0 !important;
  border: 2px solid #000000 !important;
  margin-left: -4px;
  top: -3px !important;
  -webkit-transition: border-color 0.3s ease-in;
  transition: border-color 0.3s ease-in;
}

.rs-slider-handle::before {
  content: '';
  position: absolute;
  width: 22px !important;
  height: 22px !important;
  border-radius: 50%;
  border: 2px solid #39b54a !important;
  background-color: #39b54a !important;
  margin-left: -8px !important;
  top: -5px !important;
  cursor: pointer;
  -webkit-transition: background-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out, transform 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.apexcharts-yaxis-label{
  border: 1px solid rgb(19, 3, 3) !important;
  /* fill: #b12323 !important; */
  font-size: 12.5px;
}

.left-bar-chart{
  /* max-width: 46% !important; */
    margin-right: -20px !important;
}
.right-bar-chart {
  /* max-width: 54% !important; */
  /* background-color: firebrick !important; */

}
.custom-bar-chart-body{
  padding: 0px 0px 0px 0px !important;
  margin-right: 20px !important;
}

@media only screen and (max-width: 1199px){
  .custom-bar-chart-body{
    padding: 0px 30px 0px 0px !important;
  }
}

.arrow_box{
  padding: 5px;
}

.box{
  display: block;
  justify-content: center;
  /* align-items: center; */
}

.range-display-image {
  width: 100px;
}

/** Tooltip boxes */
.tooltip-box {
  float: right;
  width: 12px;
  height: 12px;
  margin: 2px 0px 0px 9px;
  border: 1px solid rgba(0, 0, 0, .2);
  background-color: #9e9e9e;
  cursor: pointer;
}

.tooltip-box-green{
  margin: 2px 0px 0px 14px !important;
}

.table-custom-tooltip{
  border: 2px solid rgb(19, 3, 3) !important;
}

.color-tip{
  /** background-color: #fff; */
  padding: 6px 0px 6px 6px;
  font-size: small;
}

.color-tip .green{
  background-color: #39b54a !important;
}
.color-tip .red{
  background-color: #f43939 !important;
}

.value-format-filters{
  float: right;
}

.financial-separator-line{
  margin: -20px 0px 5px 0px;
  background-color: #000;
  height: 5px;
}