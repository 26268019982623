.vtt-header{
  padding: 0 ;
  height: 65px;
  position: fixed;
  top: 0;
  left :0;
  width: 100%;
  z-index: 2000;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.11);
}

.withMenu .vtt-header .mobileNav > span{
  display: block;
}

.vtt-header .logo{
  height: 65px;
  padding: 15px ;
  margin-right: 8px;
  float: left;
}
.vtt-header .logo img{
  height: 100%;
  width: auto;
  display: block;
}

.vtt-header  .ant-breadcrumb{
  padding: 0 15px;
  float: left;
}

.vtt-header  .ant-breadcrumb > span{
  height: 50px;
  line-height: 50px;
  text-transform: capitalize;
  font-size: 14px;
  padding: 3px 0;
}

.vtt-sidebarLayout{
  padding-left: 250px;
  height: 100%;
  padding-top: 65px;
  width: 100%;
  min-height: 100vh;
}


.vtt-sidebarLayout .ant-layout-sider{
  position: fixed;
  left: 0;
  height: 100%;
  top: 0;
  background: #fff;
  padding-top: 65px;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.1);
  z-index: 1 !important;
}

.vtt-sidebarLayout .ant-layout-sider .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
  background: #4a44e514;
}

.vtt-sidebarLayout .ant-layout-sider .ant-menu:not(.ant-menu-horizontal) .selected-menu{
  background: #4a44e514;
}

.vtt-sidebarLayout .ant-layout-sider .selected-menu {
  color: #4A44E5;
  background-color: #a29fed !important;
}
.vtt-sider .menuScroller .vtt-menu-item.ant-menu-item-selected{
  color: #4A44E5;
  background-color: #e4e3fb !important;
}

.vtt-content-main {
  padding: 20px 50px 50px 50px;
  background-color: #fff;
}

.vtt-content-main h4{
  font-weight: 600;
  font-size: 20px;
}

.vtt-sidebarLayout .ant-layout-sider.vtt-sider{
  background-color: #F9F9F9; 
}

.vtt-sider .menuScroller{
  height: auto;
}

.vtt-sider .menuScroller .vtt-menu-item{
  margin-top: 0px !important;
  background-color: #F9F9F9 !important;

}

.vtt-rto-return-home {
  display: flex;
  align-items: center;
  justify-content: end;
  height: 100%;
  margin-right: 20px;
}


.vtt-loader {
  position: absolute;
  width: 100%;
  height: 100% !important;
  max-height: none !important;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.877);
  z-index: 9999;
}

.vtt-loader .anticon-loading{
  margin-left: -14px;
  margin-top: -14px;
  position: absolute;
  left: 50%;
  top: 50%;
}

.vtt-breadcrumb {
  display: flex;
  padding: 0;
  align-items: center;
  height: 100%;
  text-align: right;
}

.vtt-breadcrumb-management-page{
  margin-bottom: 9px;
  margin-left: 20px;
}

@media only screen and (max-width: 767px) {
  .vtt-sidebarLayout{
      padding-left: 0;
      height: 100%;
      padding-top: 50px;
      width: 100%;
      min-height: 100vh;
  }

  .vtt-sider .menuScroller {
      height: 100% !important;
  }

  .withMenu.vtt-header{
      padding-left: 30px;
  }

  .withMenu.vtt-header .mobileNav{
    position: absolute;
    left: 0;
    top: 0;
    padding: 10px;
    display: block;
    height: 100%;
    line-height: 50px;
  }

  .vtt-sider{
      left: 0 !important;
      height: 100%;
  }
}
