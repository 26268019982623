@import 'common';

$content-top-margin: 65px;

.vtt-gen-loader{
  position: fixed;
  width: 100%;
  height: 100% !important;
  max-height: none !important;
  left: 0;
  top: 0; 
  background: rgba(255, 255, 255, 0.877);
  z-index: 9999;
}

.vtt-gen-loader .anticon-loading{
  margin-left: -14px;
  margin-top: -14px;
  position: absolute;
  left: 50%;
  top: 50%;
}

.vtt-loader-wrapper > .ant-spin-container {
  min-width: max-content !important;
  min-height: 100vh !important;
}

.vtt-gen-layout {
  min-width: max-content !important;
  min-height: 100vh !important;
  font-family: Source Sans Pro, sans-serif !important;
  .site-layout-sub-header-background {
    background: #fff !important;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }

  .ant-layout {
    margin-top: $content-top-margin;
    background: #fff !important;
  }
  .ant-layout-sider {
    border: 0 !important;
    .ant-menu {
      background: none;

      .ant-menu-item::after {
        border-right: unset;
      }
    }
  } 

  .ant-layout-header {
    padding: 0 ;
    height: $content-top-margin;
    position: fixed;
    top: 0;
    left :0;
    width: 100%;
    z-index: 10;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.11);

    .vtt-logo {
      height: 65px;
      padding: 14px 12px;
      margin-right: 8px;
      float: left;
      width: auto;
      
      &:hover {
        .header-logo-enable {
          display: none;
        }
        .sidebar-enable {
          display: block;
        }

      }
      .sidebar-enable {
        display: none;
      }

      .sidebar-toggle {
        width: 33px;
        height: 50px;
        line-height: 35px;
        text-align: center;
        font-size: 20px;
        float: left;
        cursor: pointer;
      }

      img {
        height: 100%;
        display: block
      }
    }

    .button-section {
      height: 100%;
      line-height: 50px;
      float: left;
      padding: 14px 8px;
      .risk-analysis-label {
        background-color: #5849f1;
        color: #FFF;
        padding: 10px;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 600;
      }
      .ant-menu{
        line-height: 35px;
        border: 0 !important;
        cursor: pointer;
        .ant-menu-item{
          line-height: 35px;
          border-radius: 6px;
          border: 0 !important;
          cursor: pointer;
          *{
            cursor: pointer;
          }
          &:hover{
            border: 0 !important;
            border-radius: 6px;
          }
          &.ant-menu-item-selected{
            border: 0 !important;
            background: #6244BB;
            color: #fff !important;
            border-radius: 6px;
            *{
              color: #fff !important;
            }
            &:hover{
              color: #fff !important;
              *{
                color: #fff !important;
              }
            }
          }
        }
        
      }

    }
    .end-div {
      float: right;
      padding: 8px 15px;
      height: 66px;
      line-height: 50px;
      text-align: center;
      font-size: 25px;
      color: #5849f1;
      border-left: 1px solid #eee;
      margin-left: 5px;
      .dashboard-button {
        display: block !important;
        border: solid 1px #D9D9D9 !important;
      }
      .dashboard-button:hover {
        background-color: #FFF !important;
        color: #5849f1 !important;
      }
    }

  }

  .ant-layout-footer {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }

  .inner-layout{
    padding: 20px;
    max-width: 1400px;
    margin: 0 auto;
   position: relative;
    > .ant-card{
      border: none;
      > .ant-card-body{
        padding: 0;
        box-shadow: none;
        border-radius: 4px;
        @media only screen and (min-width: 770px) {
          padding: 20px;
        }
        > .ant-tabs{
          overflow: visible !important;
        }
      }
    }
  }


  .ant-collapse-borderless{
    background: none;
  }
  .filter-section-container{
    // height: calc(100% - 107px);
    // overflow: auto;
    // @media only screen and (min-width: 770px) {
    //   height: 100%;
    // }
    .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header{
      padding: 12px 0px;
      padding-right: 25px; 
    }
    .ant-collapse > .ant-collapse-item:last-child{
      border: none;
    }
    .ant-collapse-content > .ant-collapse-content-box{
      padding: 16px 0px;
      padding-top: 0;
      .ant-space{
        display: block;
      }
    }
    .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
      right: 4px;
      left: auto;
    }
    .ant-checkbox-wrapper{
        display: flex;
        align-items: center;
        width: 100%;
        > span:last-child{
          width: calc(100% - 20px);
          display: inline-block;
          > span{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block; 
            max-width: 100%;
          }
        }
    }
  }
  .filterAction{
    padding: 15px 0 15px 0;
    margin: 0 15px;
    border-top: 1px solid #ddd;
  }
  .filterScroller{
    max-height: calc(100vh - 180px);
    overflow: auto;
    > div{
      padding: 15px;
      padding-bottom: 0;
    }
  }

  .mainCard{
    box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.11);
    border-radius: 5px;
    padding-bottom: 8px;
    .heading{
      padding: 10px 20px 0 20px;
      font-weight: 600;
      margin-bottom: -10px;
      position: relative;
      z-index: 100;
    }
  }
}
.copyrightBlock{
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px 15px;
  h6{
    font-size: 12px;
    font-weight: 600;
    margin: 0 0 5px 0;
  }
  img{
    width: 130px;
  }
}

.vtt-sidebarLayout .ant-menu-item-selected{
    color: #6244BB;
}
.vtt-sidebarLayout .ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #6244BB;
}

.ant-drawer-open {
  margin-top: $content-top-margin;
  height: calc(100vh - #{$content-top-margin}) !important
}


