@import 'common';

#{$layout-selector}{
  .top-count-card {
    height: 100%;
    min-height: 100px;
    border-radius: 5px !important;
    .ant-card-body{
      height: 100%;
    }
    .meta {
      position: absolute;
      bottom: 10px;
      right: 20px;
      text-transform: capitalize;
      h1{
        margin: 0;
      }
    }
  }
  .top-count-clickable {
    cursor: pointer;
  }

  .overview-clickable-card {
    cursor: pointer;
  }

  .top-card-icon {
    width: 50px;
    height: 50px;
    border-radius: 5px;

    img {
      width: 100%;
      height: 100%;
      padding: 5px;
    }
  }
  .overviewVisuals{
    .topRow{
      margin-bottom: 8px;
    }


  }

  .ra-student-ryt-col {
    text-align: right;

  }

  .table-remove-left {
    left: unset !important;
  }

  .value-card {
    min-height: 150px;

    @media only screen and (max-width: 1200px) {
      min-height: 140px;
    }

  }

  .value-card-value {
    position: absolute;
    top: 50px;
    font-size: 35px;

    @media only screen and (max-width: 1370px)  and (min-width: 1200px){
      font-size: 30px;
    }

    @media only screen and (max-width: 1200px) {
      top: 40px;
    }
  }
}

.nat-card {
  width: 100%;
  height: auto;
  position: relative;

  &-body{
    display: flex;
    flex-direction: column
  }
  &-body-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    span{
      font-weight: 700;
      z-index: 2;
    }
  }

  &-right-icon{
    margin-left: 8px;
    color: $natBorderColor;
    font-weight: 800 
  }
  &-missing-count{
    font-size: 30px; 
    color: $greyGray6 
  }

  &-presentage{
    font-size: 13px;
    font-weight:600;
    color: $greyGray6 
  }
}

.nat-progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  background-color: $natBgColor;
  opacity: 0.2;
  border-radius: 5px 0px 0px 5px;
}

.nat-horizontal-line {
  position: absolute;
  top: 10%;
  left: 0;
  width: 2px;
  height: 80%;
  background-color: $natBorderColor
}

.nat-popup-table {
  th {
      border-right: 1px solid $greyGray4 !important;
      font-weight: 600 !important;
      padding: 8px !important;
      height: 22px !important;
      font-size: 14px !important;
      color: $greyGray9;
  }

  td {
      border-bottom: 1px solid $greyGray4 !important;
      border-right: 1px solid $greyGray4 !important;
      padding: 8px !important;
      height: 22px !important;
      font-size: 14px !important;
      color: $greyGray8;
  }

  tr:first-child > td{
    padding: 0px !important;
    height: 0px !important;
  }
  .empty-cell-icon{
    position: absolute;
    top: 15px;
    right: 10px;
    color: $natErrorColor;
    z-index: 2;
  }
  .empty-cell-background {
      background: $natErrorBgColor;
      border: 1px solid $natErrorColor;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
  }
}

.nat-card-container{
  .nat-card-container-body{
    margin-top: 10px;
  }
}
.avetmiss-card-container{
  border: none;
  width: 100%;
  strong {
    font-weight: 600;
    font-size: 16px;
  }
  .avetmiss-card-body{
    margin-top: 20px;
    width: 100%;
  }
  .avetmiss-card-body > div {
    width: 100%;
  }
  .highcharts-container, .highcharts-root, .highcharts-background {
    width: 100% !important;
  }
}

.overview-clickable-card, 
.nat-card-container{
  strong {
    font-weight: 600;
    font-size: 16px;
  }
  height: 100%;
  border: 1px solid #DDD9D9;
  border-radius: 5px;
  .ant-typography{
    margin-bottom: 5px;
  }
  .cardHeading{
    font-weight: 600;
    max-width: 80%;
    font-size: 14px;
    line-height: 17px;
    @media only screen and (max-width: 1370px)  and (min-width: 1200px){
      font-size: 12px;
    }
  }
  h1{
    margin: 0;
  }
  .visualInfoBox{
    padding: 10px;
    margin-top: 20px;
    border: 2px solid $primary-color;
    border-radius: 4px;
    .ant-space-item{
      margin: 0 !important;
    }
    .ant-typography{
      margin: 0;
    }
    h3{
      color: $primary-color;
    }
  }
}

.overviewTable{
  padding: 20px 0;
}
.tableHeader{
  padding-bottom: 10px;
}
.overviewTable, .sectorTable, .popupTable {
  .ant-progress-success-bg, .ant-progress-bg{
    height: 10px !important;
  }
  .ant-progress-text{
    font-weight: 400;
  }
  .ant-table-thead > tr > th{
    background: #fff;
    border-top: 1px solid #e3e3e3;
    border-right: 1px solid #e3e3e3;
    font-weight: 600;
    border-bottom: 1px solid #e3e3e3;
    &:last-child{
      border-right: none;
    }
  }
  .ant-table-tbody{
    > tr > td{
      border-bottom: 1px solid #e3e3e3;
    }
  }
  .ant-table-column-sorters{
    padding: 10px 16px
  }
  .ant-table-filter-trigger.active {
    color: $primary-color !important;
  }
  .ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active{
    color: $primary-color !important;
  }
  .overview-progress-cell-high {
    .ant-progress-inner {
      border: 1px solid #EF5556 !important;
      background-color: #FFF1F0 !important;
    }
  }

  .overview-progress-cell-medium {
    .ant-progress-inner {
      border: 1px solid #F9C255 !important;
      background-color: #FFF7E6 !important;
    }
  }

  .overview-progress-cell-low {
    .ant-progress-inner {
      border: 1px solid #7CC65A !important;
      background-color: #F6FCF0 !important;
    }
  }
}

.overview-progress-cell-inline {
  .progress-cell-container {
    display: flex;
    justify-content: space-between;
  }
  .progress-cell-container {
    .progress-display-value{
      margin-top: -1px;
      margin-left: 5px;
      font-weight: 600;
    }
    .ant-progress-inner {
      border: 1px solid #aca8a8 !important;
    }
    .ant-progress-outer {
      margin-right: 0px 0px 0px 0px;
      padding-right: 0;
    }
  }
}

.sectorTable, .popupTable {
  .overview-progress-cell-high {
    .ant-progress-text {
      color: #EF5556;
      font-weight: 600;
    }
  }
  .overview-progress-cell-medium {
    .ant-progress-text {
      color: #F9C255;
      font-weight: 600;
    }
  }
  .overview-progress-cell-low {
    .ant-progress-text {
      color: #7CC65A;
      font-weight: 600;
    }
  }
}

.popupTable {
  margin-top:  20px;
}

.studentCard{
  display: flex;
  align-items: center;
  .student-cell-avatar {
    width: 36px;
    height: 36px;
    line-height: 36px;
    border-radius: 4px;
    margin-bottom: -2px;
    margin-right: 10px;
    img {
      border-radius: 4px;
      width: 36px;
      height: 36px;
    }
  }
}

.vtt-cell-no-data {
  display: inline-flex;
  padding: 4px 10px;
  background: #fafafab8; 
  border: 1px solid #f2f2f2; 
  border-radius: 6px;
  img {
    margin-top: 2px;
    margin-right: 5px;
  }
  p {
    margin-left: 4px;
    margin-bottom: 0px;
    letter-spacing: normal;
    font-weight: 600;
    opacity: 0.6;
  }
  span[role=img] {
    margin-top: 3px;
    margin-right: 2px;
  }
  &.visa-error {
    color: #EF5556;
    background: #fbebebb8;
    border: 1px solid #d96969;
    p {
      opacity: 1 !important;
    }
  }
}
