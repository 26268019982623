/*** contains only layout related css ***/
 /* branc color #003366 */

.ant-notification {
    z-index: 2000;
}

.vw-header {
    padding: 0 ;
    height: 50px;
    position: fixed;
    top: 0;
    left :0;
    width: 100%;
    z-index: 1200;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.11);
}

.vw-loader{
    position: fixed;
    width: 100%;
    height: 100% !important;
    max-height: none !important;
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.877);
    z-index: 9999;
}

.vw-loader .anticon-loading{
    margin-left: -14px;
    margin-top: -14px;
    position: absolute;
    left: 50%;
    top: 50%;
}

.vw-header .vtt-logo{
    height: 50px;
    padding: 8px ;
    margin-right: 8px;
    float: left;
}

.vw-header .vtt-logo img{
    height: 100%;
    width: auto;
    display: block;
}

.vw-header .back-btn{
    display: flex;
    align-items: center;
    height: 100%;
    max-height: 100%;
    float: left;
}

.header-button-group {
    float: right;
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 3px;
}
.jr-setting-icon {
    float: right;
    padding: 0 12px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 25px;
    color: #003366;
    border-left: 1px solid #eee;
    margin-left: 5px;
}
.jr-setting-icon:hover{

}

.vw-content {
    padding: 50px 0 0 0;
    min-height: calc(100vh);
    display: flex;
    background-color: white;
}

.vw-content-container {
    flex-grow: 1;
}
.vw-content-container .ant-layout-content{
    max-width: 1400px;
    margin: 0 auto !important;
    padding: 20px 20px 0 20px;
    display: block;
    width: 100%;
}

.vw-card-elem .cover{
    height: 110px;  
}

.vw-sidebar-footer {

}

.vw-layout-footer {

}

.vwSettingsTabs .ant-tabs-nav{
    padding: 0 20px;
    margin: 0;
}

.vwSettingsTabs .vw-unit-data-table {

}
.vwSettingsTabs .vw-unit-data-table .ant-row {
    margin:  0 !important;
}
.vwSettingsTabs .vw-unit-data-table .ant-btn-primary{
    height: 37px !important;
    color: #fff;
}

.vwSettingsTabs .vw-unit-data-table .ant-input-number{
    height: 35px;
}
.vwSettingsTabs .vw-unit-data-table .ant-input-number .ant-input-number-input-wrap{
    height: 100%;
}
.vwSettingsTabs .vw-unit-data-table  .ant-input-number-sm input{
    height: 100%;
}


.vwSettingsTabs .vw-unit-data-table .ant-table-tbody{
    border-left: 1px solid #eee !important;
    border-right: 1px solid #eee !important;;
}


.vwSettingsTabs .ant-table-wrapper{
    margin-top: 20px;
    border: 1px solid #eee !important; 
}
.madatoryLabel{
    width: 154px;
    display: block;
}


.unitStructure .ant-input-number{
    width: 100% !important;
}

.vwSettingsTabs  .vw-layout-sidebar{
    min-width: 0 !important;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    width: 302px !important;
    border-right: 1px solid #eee;
    z-index: 100;
}

.vwSettingsTabs  .vw-layout-sidebar h4{
    margin: 0;
    padding: 20px 15px ;
    font-size: 16px;
    font-weight: 600;
    color: #063C79;
}

.vwSettingsTabs .vw-unit-data-table{
    max-width: 1100px;
    margin: 0 auto;
}

.vwSettingsTabs .dealership-main-container{
    background: #fff !important;
}

.vwSettingsTabs  .site-collapse-custom-collapse{
    background: none;
}
.vwSettingsTabs .vw-sidebar-footer{
    position: static !important;
}

.vwSettingsTabs .setting-collapse{
    height: calc(100vh - 226px);
    overflow: auto;
}

.vwSettingsTabs .ant-collapse > .ant-collapse-item > .ant-collapse-header{
    padding-top:  6px;
    padding-bottom:  6px;
}
.vwSettingsTabs  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{
  padding-top: 6px;  
}

.vwSettingsTabs .ant-table-tbody > tr > td, .vwSettingsTabs .ant-table-tbody > tr > th{
    font-size: 14px !important;
}

.vwSettingsTabs  .ant-tabs-content{
    position: relative;
    height: calc(100vh - 96px);
}
.vwSettingsTabs  .ant-layout-has-sider{
    padding-left: 300px;
}

.vwSettingsTabs  .vw-sidebar-footer, .vwSettingsTabs  .vw-layout-footer{
    display: none;
}

.vwSettingsTabs .dealership-main-container{
    height: calc(100vh - 96px);
    overflow: auto;
}

.vwSettingsTabs .vw-layout-sidebar::after{
    content: "";
    width: 300px;
    height: 117px;
    background: url(https://media.dev.tentacleinsights.com/jobready/public/poweredby_octopus.png) no-repeat top center;
    background-size: 86%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #eee;
}

.vwSettingsTabs .ant-btn-primary{
    background: #003366;
}

.vwSettingsTabs .ant-btn-primary:hover,.vwSettingsTabs .ant-btn-primary:focus{
    border-color: #054a90;
    background: #054a90;
}

.vwSettingsTabs .ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active {
    color: #054a90;
}

.vwSettingsTabs .ant-pagination-item-active:focus a,.vwSettingsTabs  .ant-pagination-item-active:hover a {
    color: #054a90;
}
.vwSettingsTabs  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #054a90;
    font-weight: 600;
}
.vwSettingsTabs  .ant-tabs-ink-bar {
    background: #054a90;
}

.vwSettingsTabs .ant-tabs-tab:hover {
    color: #054a90;
}

.vw-header .ant-btn-primary{
    background: #054a90;
}

.vw-header  .ant-btn-primary:hover,.vw-header  .ant-btn-primary:focus{
    border-color: #054a90;
    background: #054a90;
}

.vwSettingsTabs .ant-collapse-item-active .ant-collapse-header{
   
}

@media only screen and (max-width: 768px) {
    .vw-content-container {
        margin: 0;
        flex-grow: 1;
        padding: 10px !important;
    }
    .vw-content {
        margin:0;
    }
    .vw-layout-sidebar{
        padding: 0 !important;
        border: 0  !important;
    }
}