@import 'common';

#{$layout-selector}{

  .vtt-gen-setting-sidebar-header {
    padding: 0 20px;
    margin: 10px 0;
    text-align: left;
  }

  .vtt-gen-setting-button-group {
    margin-top: 20px;
    @media only screen and (min-width: 770px) {

    }
    .info{
      margin-bottom: 10px;
      padding: 0 5px;
      @media only screen and (min-width: 770px) {
        margin: 0;
      }
    }
    .local-actions{
      @media only screen and (min-width: 770px) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .ant-btn{
        width: 100%;
        margin-bottom: 10px;
        margin-left: 0;
        @media only screen and (min-width: 770px) {
          width: auto;
          margin: 0;
          margin-left: 5px;
        }
      }
    }
    .save-actions{
      @media only screen and (min-width: 770px) {
        display: flex;
        align-items: center;
      }
      .ant-btn{
        width: 100%;
        margin-bottom: 10px;
        margin-right: 0;
        @media only screen and (min-width: 770px) {
          width: auto;
          margin: 0;
          margin-right: 5px;
        }
      }
    }
  }

  .condition-name {
    font-size: 14px !important;

    .combiner{
      color: #6244BB;
      font-weight: bold;
      font-size: 12px;
    }

  }

  .ri-measurement-header {
    div {
      display: inline-block;
    }
    div:last-child {
      float: right;
    }
  }

  .ri-overall-header {
    div {
      display: inline-block;
    }
    div:last-child {
      float: right;
    }
  }
  .vtt-loading-cover{
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 100;
    background: rgba(255, 255, 255, 0.729);
    text-align: center;
     .vtt-loading-image{
        width: 60px;
        height: 60px;
        overflow: hidden;
        margin: 0 auto;
        img{
          margin: -30px;
          width: 120px;
          height: 120px;
        }
     }
     .vtt-sync-loader{
      position: absolute;
      left: 50%;
      top: 20%;
      margin-left: -100px;
      width: 200px;
      height: 200px;
      background: none;
     }
     .vtt-loading-text{
      line-height: 18px;
      font-size: 14px;
      font-weight: 400;
     }
  }

}

.vtt-gen-set-sub-drawer, .vtt-gen-set-main-drawer {
  .ant-input-number:hover, .ant-input-number:focus,
  .ant-input-number-focused {
    border-color: $primary-color;
  }
  .ant-input:hover, .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper:focus, .ant-input:focus,
  .ant-input-affix-wrapper-focused{
    border-color: $primary-color;
  }

  .ant-select-selector:hover, .ant-select-selector:focus-within {
    border-color: $primary-color !important;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background: $primary-color;
  }
  .ant-checkbox-checked .ant-checkbox-inner{
    background: $primary-color;
    border-color: $primary-color;
  }

  .ant-btn-primary {
    background: $primary-color;
    border-color: $primary-color !important;
    border-radius: 4px;
    color: #fff;
    &:hover, &:focus{
      color: #fff !important;
      background: darken($primary-color, 10%) !important;
    }
  }
}

.section-select-dropdown-row {
  margin-bottom: 10px;
  border-bottom: 1px solid #d9d9d9;
  .select-all-check-box {
    margin-left: 12px;
    margin-right: 4px;
    margin-bottom: 2px;
  };
  span {
    margin-top: 5px;
    margin-bottom: 2px;
    cursor: pointer;
  }
}

.section-selected-text, .section-selected-many {
  margin-right: 4px;
  max-width: 160px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0px;
  letter-spacing: normal;
}
.section-selected-many {
  max-width: 100px;
}

.section-select-title {
  margin-bottom: 0px;
  margin-right: 5px;
}

.select-unit-progess-item {
  display: flex;
  p {
    margin-top: 1px;
    margin-left: 5px;
    margin-bottom: 0px;
    letter-spacing: normal;
    max-width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap
  }
}

.vtt-datasources-sub-header {
  display: flex;
  justify-content: space-between;
  .sync-message {
    margin-right: 2px;
    font-weight: 600;
    span[role=img] {
      margin-top: 5px;
      margin-right: 4px;
    }
    display: inline-flex;
  }
  .success {
    color: #7CC65A;
  }
  .failed {
    color: #EF5556;
  }
  .warning {
    color: #F9C255;
  }
  &.no-title {
    justify-content: end;
    margin-bottom: 10px;
  }
}