.jr-vw-user-dealerships-cell {
    max-width: 350px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.vw-dealership-train-main .ant-pagination-item:hover {
    color: unset !important
}

.vw-dealership-train-main .ant-pagination-item-active:focus {
    background-color: unset !important
}
.vw-dealership-train-main .ant-pagination-item-active:focus{
    color: unset !important;
}