.principal-profile .card-shadow{
    border: 1px #000 solid;
    box-shadow: 5px 10px #888888;
    align-items: center;
}
.principal-profile .card-blue{
    color: white;
    background-color: #3B5997;
    cursor: pointer;
}
.principal-profile .card-header-blue h5{
    color : #2D63B5;
}
.principal-profile .card-header-blue{
    border-bottom : 5px #2D63B5 solid !important;
    padding: 15px 10px 10px 2px;
}
.principal-profile .card-custom-padding{
    padding: 8px !important;
}
.principal-profile .card-text{
    float: left;
    font-size: medium;
    padding-top: 6px;
}
.principal-profile .card-value{
    float: right;
    font-size: x-large
}
.principal-profile .ReactTable .rt-thead .rt-resizable-header-content{
    color: #ffffff !important;
}
.principal-profile .ReactTable .rt-table .rt-thead .rt-tr .rt-th{
    background: #1251AE !important;
}
.principal-profile .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td{
    border-color: black !important;
}
.principal-profile .ReactTable .rt-table .rt-thead{
    border-color: black !important;
}
.principal-profile .ReactTable .rt-tbody .rt-tr-group{
    border-color: black !important;
}
.principal-profile .ReactTable .rt-tbody .rt-td{
    border-color: black !important;
}

.principal-profile .card-header-blue span{
    float: right;
}

.assignment-tasks{
    text-align: center;
}

.acc-data-table{
    text-align: center;
}

.assignment-tasks-header{
    text-align: center;
    color: #5474b5;
    text-transform: uppercase;
}

.assignment-tasks .row{
    margin-top: 4px;
}

.ant-table-thead .ant-table-cell {
    background: transparent;
    border-bottom: none;
}

table:hover,
tr:hover,
thead:hover {
    background: transparent !important;
}

.ant-table-tbody > tr.ant-table-row > td {
    border-bottom: none;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
    
}

.al-custom-dropdown{
    padding: 10px 15px;
}

.al-custom-dropdown .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #4A44E5;
    border-color: #4A44E5;
}