.dealership-sidebar {
    border-right: 1px solid #D9D9D9;
}

.vw-db-sidebar-space{
    margin-bottom: 20px;
}

.selector-list{
    border: 1px solid #D9D9D9;
    max-width: inherit;
    max-height: 300px;
    overflow-y: auto;

}

.selector-list-col {
    padding: 5px 16px !important;
    border-bottom: 1px solid #D9D9D9;
    width: 200px;

}

.selector-list-col > span:last-child{
    display: inline-block;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 15px;
}

.vw-layout-sidebar {
    padding:15px;
    border-right:1px solid #D9D9D9;
    padding-bottom: 72px;
    min-height: calc(100vh - 50px);
}

.dealership-main-container{
    padding-bottom: 72px;
    position: relative;
    overflow: hidden;
}
.dealership-main-container .vw-layout-footer{
    position: absolute;
    bottom:0;
    width: 100%;
    padding: 10px;
    border-top: 1px solid #eee;
}
.dealership-main-container .vw-layout-footer img{
    height: 50px;
}

.vw-layout-sidebar .vw-sidebar-footer{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    padding-bottom: 0;
    border-top: 1px solid #eee;
    text-align: center;
    height: 71px;
}
.vw-layout-sidebar .vw-sidebar-footer img{
    max-width: 100%;
    height: auto;
}


.pilltabs {
    margin-top: 30px;
}
.pilltabs .ant-tabs-nav-list{
    width: 100%;
}

.vw-title {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;

}

.pilltab {
    padding: 10px 5px;
    text-align: center;
    vertical-align: middle;
    display: table-cell;
    border: 1px solid #D9D9D9;
    cursor: pointer;
    font-weight: bold;
    white-space: break-spaces;

}

.pilltab-active {
    background: #003366;
    color: #FFFFFF;

}

.online-module-main {

}

.vw-card-elem {
    width: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.16);
}

.vw-card-elem .cover {
    background: linear-gradient(99.23deg, #0C5CA7 6%, #001E50 134.5%);
    width: 100%;
    min-height: auto;
    color: white;
    padding: 10px 15px;
    font-weight: bold;
    cursor: pointer;

}
.vw-card-elem .cover .icon {
    font-size: 20px;
    margin: auto;
    width: 50%;
    line-height: 2;
    float: right;

}

.vw-card-elem .stat-value {
    font-size: 20px;
    font-weight: bold;
}

.vw-card-elem .stat-description {
    color: black;
}

.filter-btn{
    padding-left: 0 !important;
}

.filter-btn .ant-btn-primary{
    top: 45%;
}

.pathway {
    display: flex;
    justify-content: center;
    max-width: 800px;
    margin: auto;
}

.pathway-scroll {
    height: 600px;
    width: 100%;
}
.pathway-scroll .pathway-icon-container {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pathway-scroll .pathway-icon {
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    font-size: 200%;
}
.pathway-scroll .pathway-node-content {
    text-align: center;
    margin: 0;
    font-weight: bold;
    cursor: pointer;
}

.vw-data-table .ant-pagination-item:hover {
    color: unset !important
}

.vw-data-table .ant-pagination-item-active:focus {
    background-color: unset !important
}
.vw-data-table .ant-pagination-item-active:focus{
    color: unset !important;
}

.vertical-timeline-element-content .vertical-timeline-element-date {
    float: right;
}

.setting-collapse {
    max-height: 800px;
    overflow-y: scroll;
}

.vw-unit-table-breadcrumb {
    font-weight: bold;
}

.vw-unit-data-table .ant-pagination-item:hover {
    color: unset !important
}
.vw-unit-data-table .ant-pagination-item-active:focus {
    background-color: unset !important
}

.vw-unit-data-table .ant-pagination-item-active:focus{
    color: unset !important;
}

.site-collapse-custom-collapse {
    overflow: hidden;
    background: #f7f7f7;
    border: 0px;
    border-radius: 2px;
}
.site-collapse-custom-collapse .ant-collapse-content > .ant-collapse-content-box{
    padding: 16px 0 16px 16px;
}

.site-collapse-custom-collapse .ant-collapse-content-box {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

@media only screen and (max-width: 768px) {
    .dealership-main-container {
        padding: 10px;
        padding-top: 50px;
        padding-bottom: 80px;
    }

    .vw-layout-sidebar{
        padding: 0 !important;
    }

    .vw-db-sidebar-space{
        margin-bottom: 100px;
    }


}