$layout-selector: ".vtt-gen-user";

#{$layout-selector} {

  .user-container {
    min-width: min-content;
    margin-top: 20px;
  }

  .vtt-user-table {
    .ant-table {
      min-width: min-content;
    }
    .ant-table-thead {
      tr {
        th {
          font-weight: bold;
          font-size: 14px;
          background-color: transparent;
          border-bottom: 1px solid #f0f0f0;
        }
      }
    }
    .ant-table-tbody {
      tr {
        td {
        border-bottom: 1px solid #f0f0f0;

        }
      }
    }
  }

  .add-user-menu-btn {
    background:#6244BB;
    color:white
  }

}
