.ext-auth-container {
    display: flex;
    width: 100vw;
    height: 100vh;
    background: #E5E5E5;
    flex-direction: column;

}
.ext-auth-main {
    margin: auto;
    top: auto;
    width: 400px;
    z-index: 1000;
}


.powered-by{
    z-index: 9999;
}
.powered-by span{
    background: #fff;
    padding: 5px 15px;
    border-radius: 20px;
    box-shadow: 0px 0px 10px #00000034;
}

.ext-auth-card {
    padding: 20px 0;
    top: auto;
    box-shadow: 0px 0px 10px #00000034;
}
.ext-auth-title {
    display:flex;
    align-items: center;
    font-weight: 600;
    font-size: 21px;
    font-family: Source Sans Pro;
    font-style: normal;
}

.ext-auth-title .icon {
    padding: 10px;
    margin-right: 10px;
    border: 2px solid #E8E8E8;

}

.ext-auth-footer {
    color: #645EF6;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 10px;
}

.ext-auth-main .powered-by {
    margin-top: 20px;
    text-align: center;
    font-size: 12px;
    display: flex;
    justify-content: center;
}

.extLoginCover .ant-card {
    border-radius: 6px !important;
    padding: 15px 0 0 0;
    box-shadow: 5px 10px 18px #8181810f;
}

.extLoginCover .ant-card .ant-btn-primary{
    margin-top: 20px;
    background: #003366;
    border-color: #003366;
}
.extLoginCover .ant-card .ant-btn-primary:hover{
    margin-top: 20px;
    background: #014e9b;
    border-color: #014e9b;
}


.extLoginCover .ant-card  .ant-card-actions li{
    padding: 0 !important;
    margin: 0 !important;
    border: 0 !important;
}
.extLoginCover .ant-card  .ant-card-actions{
    border: 0 !important;
}
.customLoginHEading img{
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.customLoginHEading{
    padding: 0 10px 0 10px;
}
.customLoginHEading span{
    text-align: center;
    font-size: 20px;
    margin-top: 10px;
    display: block;
    font-weight: 600;
}
.samlOrLine{
    font-size: 17px;
    position: relative;
    text-align: center;
    margin: 20px 0;
}
.samlOrLine span{
    background: #fff;
    padding: 0 10px;
    position: relative;
    z-index: 10;
}
.samlOrLine:after{
    content: "";
    height: 1px;
    position: absolute;
    right: 0;
    width: 100%;
    background: #D8D8D8;
    top: 50%;
}
.customButtonsSSO{
    width: 100%;
    max-height: 240px;
}
.customButtonsSSO .ant-btn{
    margin: 0;
    color: #222;
    border-color: #ddd;
}
.customButtonsSSO .ant-btn:hover{
    color: #003366;
    border-color: #003366;
}
.samlBtn{
    margin-bottom: 10px;
    position: relative;
    text-transform: uppercase;
}
.samlImg{
    width: 100%;
    vertical-align: top;
    position: absolute;
    right: 10px;
    width: 30px;
    height: 30px;
    top: 50%;
    margin-top: -15px;
}

.vtt-login-btn {
    background-color: #6244BB !important;
    border: 1px solid #6244BB !important;
}

.vtt-login-btn:hover {
    background-color: #704fd0 !important;
    border: 1px solid #704fd0 !important;
}

@media screen and (max-width: 400px) {
    .ext-auth-main {
        width: 100%;
    }
}