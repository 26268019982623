.signin-container{
    height: min-content;
    margin-top: 5%;
}
.sign-up-form-container .ant-card-body{
    padding: 40px 60px    
}
.pricing-container{
    padding: 40px 0;
    min-height: calc(100vh - 65px);
}

.layout-row{
    height: min-content;
    display: flex;
    align-items: center;
}
.layout-video{
    height: min-content;
}
.layout-video .intro{
    font-size: 16px;
}
.layout-form{
    height: auto;
}

.layout-card{
    height: 100%;
}

.layout-card > div{
    min-height: 100% !important;
}

.login-form-typography-text{
    cursor: pointer;
    color: rgb(97, 165, 230);
}

.partner-portal-form{
    animation: login-form-slide-from-left 300ms ease-out forwards;
}

.partner-portal-header{
    margin-top: 70px;
}


.partner-loader {
    position: absolute;
    width: 100%;
    height: 100% !important;
    max-height: none !important;
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.877);
    z-index: 9999;
}

.partner-loader .anticon-loading{
    margin-left: -14px;
    margin-top: -14px;
    position: absolute;
    left: 50%;
    top: 50%;
}

/* slide in keyframe for form animation */
@keyframes login-form-slide-from-left {
    from {
      opacity: 0;
      transform: translateX(-3rem);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
}

.partner-portal-form-heading {
    padding-top: 5px;
    color: #202124;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.3;
    text-align: center;
}

.partner-portal-form-sub-heading {
    padding-top: 15px;
    color: #202124;
    font-size: 14px;
    line-height: 1.3;
    text-align: center;
}

.policy-agreement {
    padding: 3rem 6rem 3rem 6rem;
}

.policy-agreement-header{
    text-align: center;
    margin-bottom: 20px;
}
.policy-agreement li{
    margin-left: 2rem;
    list-style-type:decimal;
}

.policy-agreement .table-of-contents li{
    list-style-type: circle;
}

.ul-common{
    padding-bottom: 14px;
}

.dot-ul li{
    list-style-type: disc;
    padding-bottom: 14px;
}

.policy-agreement h2{
    font-size: 1.43em;
    font-weight: normal;
    line-height: 1.2;
}

.policy-agreement p{
    font-size: 1em;
    line-height: 1.714;
    font-weight: normal;
    letter-spacing: -0.005em;
}

.policy-agreement-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.75);
    border: none;
    transition: 0.5 ease-in-out;
}

.policy-agreement-wrapper {
    position: fixed;
    background-color: #d6d9e3;
    top: 2vh;
    left: 22.5%;
    width: 55%;
    border-radius: 14px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 995;
    animation: login-form-slide-from-left 600ms ease-in forwards;
}

.policy-agreement-take-consent-box-wrapper {
    left: 22.5%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    padding: 0;
    position: fixed;
    transition: 0.3s;
    border: 1px solid gray;
    border-radius: 1%;
    z-index: 999;
    width: 55%;
    height: 200px;
    background-color: hsla(0,0%,4%,.4);
    transform: scale(0);
    bottom: 0;
    transition: transform ease-out 150ms, left ease-out 150ms;
}

.policy-agreement-take-consent-box-wrapper-show{
    bottom: 20px;
    transition: all 250ms;
    transform: scale(1);
}

.policy-agreement:hover  {
    transition: all 250ms;
    transform: scale(1.06);
}
.policy-agreement-take-consent-box {
    background-color: white;
    opacity: 1;
    top: 25%;
}

.policy-agreement-accept-btn {
    margin-left: 15px;
    color: blue;
}
    
/* MEDIA */
@media only screen and (max-width: 800px) {
    .layout-card{
        height: auto;
    }
    .layout-row{
        flex-direction: column;
    }
}
@media only screen and (max-width: 768px) {
    .sign-up-form-container .ant-card-body{
        padding: 40px 35px    
    }
}