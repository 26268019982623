@import '~antd/dist/antd.css';


.acc-ceo-profile-selector-layout {
    align-items: center;
    overflow: auto;
}

.acc-ceo-profile-selector-content-wrapper {
    border: 1px solid #dadce0;
    margin: 40px 0 100px 0;
    padding: 60px 0 60px 0;
    background-color: #fff;
    border-radius: 8px;
}

.acc-ceo-profile-selector-acc-image {
    justify-content: center;
    align-items: center;
    text-align: center;
}

.acc-ceo-profile-selector-divider-choose-account {
    padding-top: 30px;
    color: #202124;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.3;
    text-align: center;
}


.acc-ceo-profile-selector-row {
    width: 500px; 
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 12px 0 40px 0;
 }

.acc-ceo-profile-selector-button {
    cursor: pointer;
    background-color: #fff;
    margin: 10px 10px 0 1px;
    height: 19vh;
    border: none;
    color: #3c4043;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
}


.acc-ceo-profile-selector-typography {
    margin-top: 12px;
    font-size: 19px;
}


.acc-ceo-profile-selector-button img{
    width: 50%;
    height: 100%;
    border-radius: '3px';
}

.acc-ceo-profile-selector-button:hover{
    background-color: #E8F0FE;
}

.acc-ceo-profile-selector-divider-login-to {
    direction: ltr;
    padding: 3vh 0 3vh 5vh;
    font-style: italic;
    font-size: 14px;
    text-align: left;
    line-height: 1.3333333;
    word-break: break-all;
}

.acc-ceo-profile-selector-icon {
    margin-right: 10px;
}
