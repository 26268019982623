@import '../analysis/common.scss';

.vtt-rto-content-on-boarding {
  padding: 20px 50px 50px 50px;
  background-color: #fff;

  .on-boarding-form {
    margin-top: 100px;
    justify-items: center;
    display:block;
    h4 {
      text-align: center;
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 40px;
    }
    form {
      width: 500px;
      .admin-email-row {
        margin-bottom: 0px
      }
      .admin-email-check-box {
        margin-top: 2px;
        margin-bottom: 20px;
      }
      .staging-db-row {
        margin-bottom: 0px
      }
      .staging-db-check-box {
        margin-top: 2px;
        margin-bottom: 20px;
      }
    }
    .ant-spin-spinning {
      span[role=img] {
        font-size: 36px;
        color: $primary-color;
      }
    }
    .ant-spin-container {
      display: flex;
      justify-content: center;
    }
  }
};