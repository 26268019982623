.vtt-dashboard-list-item {
  border: 1px solid #E8E8E8 !important;
  padding: 15px 15px 15px 15px !important; 
  border-radius: 5px !important;
  position: relative;
}
.vtt-dashboard-list-item .linkWrap{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.vtt-dashboard-list-item:hover{
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.11);
}
.activeSwitch{
  padding: 8px;
  margin-left: -8px;
  display: inline-block;
  position: relative;
  z-index: 10;
}
.activeSwitch > span{
  padding-left: 4px;
}


.vtt-dashboard-list-item .ant-list-item-meta-title{
    font-size: 20px;
    font-weight: 600;
}

.vtt-dashboard-list-item .image {
  width: 100%;
  height: 100%;
  max-width: 200px;
  max-height: 150px;
  cursor: pointer;
}

.vtt-dashboard-button-group{
  display: flex;
  align-items: end;
  justify-content: right;
  position: relative;
  z-index: 10;
}

.vtt-dashboard-list-item .btn-group {
  margin: auto;
  text-align: right;
}

.vtt-logout-userName-lbl {
  margin: 2px;
  font-size: 20px;
  font-weight: bold;
}

.vtt-logout-role-lbl {
  margin: 2px;
  font-size: 16px;
}

.vtt-logOut-icon {
  display: flex;
  align-items: center;
  height: 60%;
  padding-left: 12px;
  padding-right: 5px;
  border-left: 1px solid #a8a8a8;
}

.vtt-setting-btn-HList {
  display: flex;
  flex: 1;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 1px;
}

.vtt-setting-btn-VList {
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: left;
  margin: 2px;
  height: 100%;
  padding-right: 10px;
}

.vtt-setting-icon {
  float: right;
  padding: 0px 10px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 25px;
  color: #003366;
  border-left: 1px solid #eee;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 40px;
  cursor: pointer;
  border: none;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.11);
}

.vett-setting-icon {
  float: right;
  padding: 0 12px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 25px;
  color: #003366;
  border-left: 1px solid #eee;
  margin-left: 5px;
}

.vtt-on-click-div{
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .vtt-dashboard-list-item {
    flex-direction: column;
  }

  .vtt-dashboard-list-item .image {
    max-width: unset;
    max-height: unset;
  }
}

.vtt-settings-validate-logics div[role=alert] {
  width: 300px;
  position: absolute;
  line-height: 14px;
  left: -190px;
}