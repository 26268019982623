.management-profile .center-text{
    width:100%;
    text-align:center;
}

.center-align {
    width:100%;
    text-align:center;
}

.management-profile .card-custom-margin {
    margin-top: 10px;
}

.management-profile .table-bordered td, .management-profile .table-bordered th {
    border-color: #ccc9c9;
}

.management-profile .active-btn {
    color: #fff !important;
    background-color: #1251AE !important;
}
.management-profile .cursor-pointer {
    cursor: pointer;
}
.management-profile .blue-cell {
    color: #fff !important;
    background-color: #1251AE !important;
}