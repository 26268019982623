.ceo-profile .card-blue {
    background: #ffffff;
    opacity: 0.1;
    background-color: #3b5997;
    cursor: pointer;
}

.ceo-profile .card-header-blue:h5 {
    color: #2d63B5;
}

.ceo-profile .card-header-blue {
    border-bottom: 5px #2d63B5 solid !important;
    padding: 15px 10px 10px 2px;
}

.ceo-profile .card-custom-padding {
    padding: 10px !important;
}

.ceo-profile .card-text {
    float: none;
    font-size: large;
    font-weight: bold;
    padding-top: 6px;
    display: flex !important;
    justify-content: center !important;
}

.ceo-profile .card-value {
    float: none;
    font-size: x-large;
    display: flex !important;
    justify-content: center !important;
    color: #164a90;
    font-weight: bold;
}

.card-bg {
    background-color: transparent !important;
}

.custom thead tr th {
    background-color: #164a90;
    color: white !important;
    text-align: center;
} 

.custom tbody tr th {
    background-color: #164a90;
    color: white !important;
    text-align: left;
} 

.custom-td-right {
    text-align: right;
}

.custom-td-center {
    text-align: center;
}

.custom-nodata {
    text-align: center;
}

.ceo-profile .stp-activity-filter-btn {
    background-color: #6a96d1;
    margin-bottom: 5px;
    border-radius: 5px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: relative;
}
.ceo-profile .stp-activity-filter .active {
    background-color: #07408a;
}
.ceo-profile .stp-activity-filter-btn:hover {
    background-color: #07408a;
}
.ceo-profile .stp-activity-filter-btn span {
    color : #fff !important;
    font-size: small;
    position: absolute;
    top: 20%;
}

.ceo-profile .card-header-blue span{
    float: right;
}

.ceo-profile tr, td, th{
    font-size: 16px !important;
}