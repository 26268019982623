.tass-web .page-wrapper {padding-left: 220px;} 
.tass-web  .page-sidebar{
    width: 220px !important;
    left: 0;
    box-shadow: 3px 1px 3px 1px rgba(000, 000, 000, 0.05) !important;
}
.tass-web  .main-header-left{
    height: auto !important;
    box-shadow: none !important;  
    padding: 40px 20px !important;  
    
}
.tass-web  .main-header-left img{
    width: 80% !important;
}
.tass-web .page-wrapper .sidebar{
    box-shadow: none !important;  
}
.tass-web  .logo-wrapper{
    padding: 0 !important;
}
.tass-web  .sidebar-menu{
    padding: 0 !important;
    border-top: 1px solid #E2E2E2;
}
.tass-web  .sidebar-menu li a{
    padding: 12px 15px !important;
    color: #1F4780 !important;
    border-bottom: 1px solid #E2E2E2;
    position: relative;
}
.tass-web  .sidebar-menu li.active a{
     color: #39B54A !important;
}
.tass-web  .sidebar-menu li.active a:before{
    content:"";
    width: 4px;
    height: 30px;
    right: 0;
    top: 50%;
    margin-top: -15px; 
    position: absolute;
    background: #39B54A;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
.tass-web  .sidebar-menu li a:hover{
    color: #39B54A !important;
}
.tass-web .page-wrapper .page-main-header{
    width: 100% !important;
    left: 0 !important;
    top: 0;
    padding-left: 210px;
    padding-right: 10px;
    box-shadow: 3px 1px 3px 1px rgba(000, 000, 000, 0.05) !important;
}

.tass-web  .rs-slider-mark{
    top: -27px;
}
.tass-web   .rs-slider-with-mark:not(.rs-slider-vertical) {
    margin-top: 19px !important; 
    margin-bottom: 0 !important; 
}
.tass-web  .filter-panel{
    width: 100%;
}
.tass-web  .yearfilter{
    width: 500px;
    float: left;
    max-width: 48%;
}

.tass-web   .rs-slider-handle::before{
    border-radius: 0 !important;
    width: 18px !important;
    height: 18px !important;
    top: -4px !important;
    transform:rotate(45deg);
}

.tass-web  .monthfilter{
    width: 500px;
    float: right;
    max-width: 48%;
}
.tass-web  .month-filter {
    width: 100% !important;
}

.rs-slider-pass.rs-slider-active{
    font-weight: 600;
    /* font-size: 20px;
    margin-top: -10px; */
}

.burgerMenu{
    position: absolute;
    left: 0;
    top: 0;
    padding: 20px 10px;
    display: block;
    height: 60px;
    display: none;
}
.filterMenu{ 
    position: absolute;
    right: 0;
    top: 0;
    padding: 20px 10px;
    display: block;
    height: 60px;
    display: none;
}

.mobile-filter-panel{
    position: fixed;
    width: 300px;
    max-width: 100%;
    height: 100%;
    right: 0;
    right: -400px;
    top: 0;
    background: #fff;
    z-index: 9999;
    padding: 40px;
    padding-top: 80px;
    box-shadow: 3px 1px 5px 5px rgba(000, 000, 000, 0.1) !important; 
}
.mobileMenuHead{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    padding-left: 70px;
    border-bottom: 1px solid #eee;
}
.mobileMenuHead h6{
    margin: 0;
    line-height: 30px;
    font-weight: 600;
}
.mobileMenuHead .close{
    left: 0;
    position:  absolute;
    padding: 4px 13px;
    top: 0;
    line-height: 50px;
    width: 50px;
    height: 50px;
    text-align: left;
    border-right: 1px solid #eee;
    opacity: 1 !important;
}


.mobile-filter-panel .yearfilter{
    max-width: 100% !important;
}
.mobile-filter-panel .monthfilter{
    max-width: 100% !important;
}

.tass-web .page-body{
    padding: 0 30px !important;
}
.tass-web  .page-wrapper .page-body-wrapper .page-header{
    padding-bottom: 15px !important;
}

.tass-web .card {
    padding: 0 20px;
}
.border-right{
    border-right: 1px solid #eee;
}
.tass-web .card .card-header{
    padding: 15px 0 !important;
    font-size: 14px;
    padding-bottom: 10px !important;
    border: 0;
}
.profitTable table{
    height: 300px !important; 
}

.tass-web .textCard{
    color: #222;
}

.tass-web .textCard h1{
    font-size: 25px;
    margin: 0;
}
.tass-web .card{

}

.tass-web .textCardCover .textCard{
    border: 1px solid #2a3242;
    box-shadow: 1px 5px 24px 0 rgb(68 102 242 / 5%); 
    border-radius: 5px;
}

.tass-web .diffrence{
    font-weight: 600;
    color: #3ab44a;
}
.tass-web .prev-month-value.down  .diffrence {
     color: red; 
}

.low-hilite{
    opacity: 0.8 !important;
    display: block;
}
.prev-month-value.right-text{
    text-align: right;
}
.prev-month-value.right-text h5{
    padding-right: 25px;
}
.prev-month-value h5{
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    padding-right: 20px;
    position: relative;
} 
.textCardCover  .card-header{
  display: none;
}
.tass-web  .textCardCover .textCard{
    width: 100%;
}
.tass-web  .textCardCover  .card-body{
    position: relative;
}
.tass-web  .textCardCover  .card-body .tooltip-box{
    position: absolute;
    top: 8px;
    right: 8px;
}
.cardBlockWrapper{
    padding-top: 41px;
}

.prev-month-value h5 .fa{
    position: absolute;
    right: 0;
    top: 0;
    font-size: 16px;
    color: #3ab44a;
}

.prev-month-value.down  h5  .fa{
    color: red; 
}

.prev-month-value .headingSmall{
    font-size: 10px !important;
    font-weight: 600;
      white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
  
}

.profitTable .table-fix-height{
    height: 300px !important; 
    max-height: 300px !important;
}
.tass-web .card  .card-body{
    padding: 15px 0;
    font-size: 14px; 
    padding-top: 0;
}
.tass-web .card .card-header h5{
    font-size: 14px;
}

.chart-widget-dashboard .media .media-body h5 svg{
    width: 11px;
    height: 11px;
}

.cardSegment h3{

}
.cardSegment{
    padding: 9.6px 10px;
    text-align: center;
}
.cardSegment h4{
    font-size: 20px;
    text-align: center;
    font-weight: 800;
    margin: 0;
}

.cardSegment h5{
    font-size: 14px;
    text-align: center;
}
.smallTextBlock{
    padding: 5px 0;
    background: #2a3242;
    color: #fff;
    border-top: 1px solid #2a3242;
}
.smallTextBlock h6{
    font-size: 13px;
    text-align: center;
    margin: 0;
}

.comingSoonCover{
    padding: 100px 0; 
    position: relative;
    z-index: 5;
}
.comingSoonCover .textBlock{
    text-align: center;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #eee;
}
.comingSoonCover .contentBlock{
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
    padding-bottom: 5px;
    background: #fff;
    box-shadow: 0px 0px 17px rgb(0 0 0 / 51%);
    border-radius: 10px;
}
.comingSoonCover .contentBlock p{
    font-size: 15px;
    line-height: 23px
}
.comingSoonCover .contentBlock img{
    max-width: 100%;
}

.bgBlur{
    position: fixed;
    width: calc(100% - 220px);
    height: calc(100vh - 80px);
    top: 80px;
    left: 220px;
    z-index: 5;
    text-align: center;
    filter: blur(3px);
    -webkit-filter: blur(3px);
    opacity: 0.5;
}
.bgBlur img{
    max-width: 100%;
}
.footer{
    background: #fff;
    position: relative;
    box-shadow: 3px 1px 3px 1px rgba(000, 000, 000, 0.05) !important;
}


@media (max-width: 1000px) {
    .tass-web  .page-main-header {
        padding-left: 10px !important;
    }
    .tass-web  .page-main-header .main-header-right{
        padding: 10px  !important;
        padding-right: 30px !important;
        padding-left: 120px !important;
    }
    .tass-web  .main-header-left{
        width: 100px;
        padding: 0 !important;
    }
    .tass-web .page-sidebar{
        left: -300px;
    }
    .tass-web .page-wrapper .page-main-header .main-header-left .logo-wrapper img{
        height: auto !important;
    }
    .tass-web .page-wrapper{
        padding: 0 15px;
    }
    .tass-web .logo-wrapper{
        position: absolute;
        left: 48px;
        top: 7px;
        width: 90px;
    }
    .burgerMenu{
        display: block;
    }
    .mainMenuOpen .page-sidebar{
        left: 0;
    }
    .bgBlur{
        width: 100%;
        left: 0;
    }
}

@media (max-width: 850px) {
    .tass-web  .yearfilter{
        display: none;
    }
    .tass-web  .month-filter {
        display: none;
    }
    .filterMenu{
        display: block;
    }
    .filterMenuOpen .mobile-filter-panel{
        right: 0;
    }
    .mainMenuOpen .page-sidebar{
        left: 0;
    }
}