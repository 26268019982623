@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700&display=swap');

*{
    font-family: 'Source Sans Pro', sans-serif !important;
    outline: none;
}

.in-sidebarLayout .ant-layout-sider .ant-menu-inline{
    border-right: 0 !important;
}
.in-sidebarLayout .ant-layout-sider .ant-menu-inline .ant-menu-item:hover{
    color: #4A44E5;
}
.DayPicker-Day--today{
    color: #4A44E5 !important;
}


.in-sidebarLayout .ant-layout-sider .ant-menu-item-selected{
    color: #4A44E5;
}
.ant-pagination-item-active {
    font-weight: 500;
    background: #fff;
    border-color: #4A44E5;
    color: #4A44E5;
}
.ant-pagination-item-active:hover, .ant-pagination-item-active:focus , .ant-pagination-item:hover{
    font-weight: 500;
    background: #4A44E5;
    border-color: #4A44E5;
    color: #fff !important;
}
.ant-btn{
    border: 1px solid rgb(95, 95, 95);
    color: rgb(97, 97, 97);
}
.backLink .ant-btn-icon-only.ant-btn-sm:hover, .backLink .ant-btn-icon-only.ant-btn-sm:focus{
    background: #4A44E5 !important;
    border-color: #4A44E5 !important;
    color: #fff !important;
}

.ant-switch-checked{
    background: #4A44E5;
}
.ant-btn-primary{
    background: #4A44E5;
    border-color: #4A44E5;
    border-radius: 4px;
    color: #fff;
}
.ant-btn-primary:hover{
    background: #5a55e9;
    border-color: #4A44E5;
    border-radius: 4px;
}



.in-sidebarLayout .ant-layout-sider .ant-menu-inline .ant-menu-item::after{
    border-right-color: #4A44E5 !important;
}

h1{
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
}

h5{
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
}
.textRight{
    text-align: right !important;
}

/* make mid center */

.makeVerticleCenter{
    display: table;
    height: 20px;

}
.makeVerticleCenter > span{
    display: table-cell;
    vertical-align: middle;
}

.mainMetaDescription {
    margin-bottom: 10px;
}

.mainMetaDescription .ant-card-meta-title{
    font-size: 20px;
    line-height: 25px;
    margin: 0;
    font-weight: 600;
}
.mainMetaDescription .ant-card-meta-description{
    color: #222;
}

.in-container{
    margin: 0 auto;
    max-width: 1300px;
    padding: 20px;
}

.course-in-container{
    margin-top: 60px;
    max-width: 1300px;
    padding: 20px;
}

.userInfoCard .ant-card-body {
    padding: 10px;
    height: 100%;
    width: 100%;
}

.userInfoCard-btn{
    margin-left: 10px;
}

.userInfoCard .ant-card-body .ant-col p{
    margin-bottom: 5px;
}

.in-container .instruction-card-child-components{
    padding-left: 20px;
    padding-right: 20px;
}

.credentialManagementButtonTabs{
    border: none;
    margin-bottom: 2px;
}

.credentialManagementTab-active{
    color: #4A44E5;
    margin-bottom: 0;
    padding-bottom: 15px;
    border-bottom: 2px solid #4A44E5;
}

.credentialManagementTab-inactive{
    margin-bottom: 0;
    padding-bottom: 15px;
}

.instruction-card{
   justify-content: flex-start;
}

.instruction-card-icon{
   padding-top: 5px;
   width: 20px;
}

.instruction-card-info{
    padding-left: 15px;
}

.circle{
    border: 2px solid red;
    background-color: #FFFFFF;
    height: px;
    border-radius:50%;
    width: 100px;
}

.in-sectionCover{
    padding: 20px;
    background: #fff;
    margin-bottom: 12px;
    border-radius: 4px;
}

.in-sectionCover-disabled{
    padding: 20px;
    background: #fff;
    margin-bottom: 12px;
    border-radius: 4px;
    pointer-events: none;
}

.in-section-no-data {
    padding: 30px 30px;
    background: #fff;
    box-shadow: 0 0 9px rgb(0 0 0 / 11%);
    border-radius: 5px;
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
}

.in-section-no-data h3 {
    margin: 15px 0 0;
    font-weight: 600;
    font-size: 15px;
    text-align: center;
}

.custom-paginate-group button{
    margin: 0 4px;
    display: inline-block;
}

.custom-paginate-group{
    text-align: center;
    border-top: 1px solid #eee;
    padding-top: 10px;
}

.in-header{
    padding: 0 ;
    height: 50px;
    position: fixed;
    top: 0;
    left :0;
    width: 100%;
    z-index: 2000;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.11);
}

.settingIcon{
    float: right;
    padding: 0 12px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 25px;
    color: #5849f1;
    border-left: 1px solid #eee;
    margin-left: 5px;
}
.backLink{
    float: left;
    height: 50px;
    line-height: 50px;
    padding: 0 5px;
}
.backLink .ant-btn{
    padding: 0 3px;
}

.settingIcon:hover{
    background: #5849f1;
    color: #fff;
}
.mobileNav{
    display: none;
}

.withMenu.in-header .mobileNav > span{
    display: block;
}
.in-header .logo{
    height: 50px;
    padding: 8px ;
    margin-right: 8px;
    float: left;
    border-right: 1px solid #D9D9D9;
}
.in-header .logo img{
    height: 100%;
    width: auto;
    display: block;
}

.chartRiskCondition{
    height: 100%;
    position: relative;
}
.chartRiskCondition:after{
    content: "";
    width: 1px;
    height: 100%;
    top: 0;
    left: -15px;
    background: #eee;
    position: absolute;
}

.in-header  .ant-breadcrumb{
    padding: 0 15px;
    float: left;
}

.in-header  .ant-breadcrumb > span{
    height: 50px;
    line-height: 50px;
    text-transform: capitalize;
    font-size: 14px;
    padding: 3px 0;
}

.in-sidebarLayout{
    padding-left: 250px;
    height: calc(100vh);
    padding-top: 50px;
    width: 100%;
}

.pri-header{
    height: 50px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.11);
    background: #fff;
    position: relative;
    z-index: 2;
}
.header-radio-col {
	position: relative;
	background: #ffffff;
    border-right: solid 2px #DFDFDF;
    max-width: 243px;
    min-width: 243px;
    width: 243px;
    margin: -15px 0;
}
.header-radio-button-group:after, .header-radio-button-group:before {
	left: 100%;
	top: 50%;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}
.header-radio-button-group:after {
	border-color: rgba(136, 183, 213, 0);
	border-left-color: #ffffff;
	border-width: 6px;
	margin-top: -6px;
}
.header-radio-button-group:before {
	border-color: rgba(194, 225, 245, 0);
	border-left-color: #DFDFDF;
	border-width: 8px;
	margin-top: -8px;
}
.header-radio-col > .ant-form-item{
    margin-bottom: 0 !important;
}
.no-student-select-card{
    text-align: center;
}
.no-student-select-card .ant-card-body{
    max-width: 1300px;
}
.no-student-select-card .no-student-select-icon svg{
    width: 31px !important;
    height: 31px !important;
    color: #4A44E5;
}
.no-student-select-card h3{
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 25.5px;
    letter-spacing: -0.4px;
}
.student-profile-content .singleColumnLayout{
 padding-top: 30px !important;
}
.student-profile-sec-header .ant-form-item-label{
    text-align: left;
}
.student-profile-sec-header{
    margin-top: 52px;
}
.student-profile-sec-header .ant-select-selection-item{
    text-align: left;
}
.student-profile-sec-header .ant-select-selection-placeholder{
    text-align: left;
}
.student-profile-sec-header .ant-row-space-between > .ant-row{
    padding: 0 10px;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}
.student-profile-sec-header .ant-row-space-between > .ant-row h5{
    color: #333;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}
.student-profile-sec-header .ant-row-space-between > .ant-row .h6{
    color: #4F4F4F;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    margin-bottom:  0px;
}
.student-profile-sec-header .student-detail-col{
    text-align: left;
    height: fit-content;
}
.student-profile-sec-header .ant-layout-header{
    height: fit-content;
}
.student-profile-sec-header .header-form-item{
    margin-bottom: 10px !important;
}
.student-profile-sec-header .ant-layout-header .ant-form-item-no-colon{
   margin: 0;
}
.student-profile-sec-header .ant-form-item-label > label{
    height: 13px; 
}
.student-profile-sec-header .ant-layout-header .ant-row{
    text-align: right;
}
.student-profile-sec-header .in-container{
    padding: 0;
}
.sec-header {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.11);
    background: #fff;   
    padding:  10px 15px; 
}
.sec-header .in-sectionCover{
    padding: 0;
    margin: 0;
}
.non-admin-header-row-container .header-col{
    margin-right: 10px;
}
.non-admin-header-row-container .ant-form-item{
    margin-bottom: 0;
}
.custom-filter-header .ant-form-item{
    width: 100%;
    flex-wrap: nowrap;
    flex-direction: column;
}
.custom-filter-header .in-container{
    padding: 0 20px;
}
.custom-filter-header .in-container .header-col{
    margin-right: 13px !important;
}
.custom-filter-header .in-container .ant-form-item{
    margin-bottom: 0px;
}
.custom-filter-header .ant-form-item-label > label{
    height: auto !important;
    width: 100%;
    text-align: left;
}
.custom-filter-header .ant-form-item-no-colon{
    margin-bottom: 0 !important;
}
.custom-filter-header .ant-form-item-label{
    height: auto;
    line-height: 1.5;
}
.custom-filter-header .ant-col{
    width: auto !important;
}
.custom-filter-header .header-radio-col{
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}
.center-header .search-header-enabled .ant-row{
    max-width: 1300px;
    margin: auto;
    padding-left: 4px;
    padding-right: 4px;
}
.center-header .ant-form-item-label{
    text-align: left;
}
.search-header-enabled .ant-layout-sider{
    padding-top: 130px !important;
}
.search-header-enabled .in-sidebarLayout {
    padding-top: 130px; 
}
.search-header-enabled .course-in-container{
    margin: 0 auto;
}
.search-header-enabled .al-custom-dropdown{
    text-align: left;
}
.search-header-enabled .select-option-dropdown{
    text-align: left;
}
.dashboard-admin-center-button{
    float: right;
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 3px;
}
.in-sidebarLayout > .in-loader{
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 1900 !important;
}

.in-sidebarLayout .ant-layout-sider{
    position: fixed;
    left: 0;
    height: 100%;
    top: 0;
    background: #fff;
    padding-top: 50px;
    box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.1);
    z-index: 1 !important;
}


.in-sidebarLayout .ant-layout-sider .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background: #4a44e514;
}

.in-sidebarLayout .ant-layout-sider .ant-menu:not(.ant-menu-horizontal) .selected-menu{
    background: #4a44e514;
}

.in-sidebarLayout .ant-layout-sider .selected-menu {
    color: #4A44E5;
}

.in-sidebarLayout .ant-layout-sider .normal-menu {
    color: #000000D9;
}

.in-iconCover{
    height: 50px;
    padding-left: 60px;
    position: relative;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 15px;
    z-index: 100;
}
.in-iconCover .makeVerticleCenter span{
    height: 50px;
}

.in-iconCover .in-icon{
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    background: #52C41A;
    padding: 13px 15px;
    border-radius: 4px;
}

.textWidget{
    padding: 15px;
    margin: 15px 0;
    border-radius: 4px;
    position: relative;
    margin-bottom: 0;
    z-index: 0;
}
.row-pie-chart {
    min-width: 150px;
}
.colorscreen{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 1px solid #eee ;
    border-radius: 4px;
}
.valueContainer{
    position: relative;
    z-index: 100;
}

.textFragment{
    margin-top: 10px;
}

.textFragment h5{
    margin: 0;
    font-size: 14px;
    text-transform: capitalize;
}

.textFragment h1{
    margin: 0;
}




/* table styles */
.section-meta{
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.table-cell-meta{
    padding-left: 50px;
    position: relative;
    min-width: 220px;
    min-height: 40px;
}

.tableCoverCard  .ant-table-tbody > tr > td{

}
.tableCoverCard .ant-table-column-sorter-full{
    margin-top: -6px !important;
}

.tableCoverCard .ant-table-row .ant-table-cell{
    border-bottom: 1px solid #eee;
}

.taskFilterHeading{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.taskFilterHeading h4{
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
}
.taskFilterHeading .ant-form-item-label > label{
    margin: 0 !important;
}
.taskFilterHeading .ant-form-item{
    margin-bottom: 0 !important;
    border-radius: 4px !important;
}
.taskFilterHeading .ant-btn-primary{
    margin-left: 10px !important;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
    padding: 12px 16px;
}
.tableCoverCard .ant-table-thead th{
    background: none;
    border-bottom: 1px solid #f0f0f0;
    border-top: 1px solid #f0f0f0;
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    background: #fff;
}
.tableCoverCard  td{
}
.tableCoverCard .ant-table-thead th .ant-table-column-sorters{
    padding: 0;
}

.tableCoverCard  .ant-card-extra .ant-radio-button-wrapper{
    margin-left: 5px;
    border: 0 !important;
    border-radius: 4px;
}
.tableCoverCard  .ant-card-extra  .ant-radio-button-wrapper::before{
    display: none !important;
}


.tabeSolidGauge .highcharts-tracker{
    opacity: 1 !important;
}
.tabeSolidGauge .highcharts-tracker >span{
    margin-top: -13px !important;
}
.tabeSolidGauge {
    overflow: hidden;
}
.tabeSolidGauge > div{
    margin: -10px 0 -27px 0;
    margin-left: -30px;
}
.studentSumPie{
    overflow: hidden;
}
.studentSumPie > div{
    margin: -20px 0;
}
.studentSumPie .pie-chart-Wrapper {
    margin: 0;
    padding-right: 0 !important;
}
.pie-chart-Wrapper {
    position: relative;
}
.pie-chart-Wrapper .no-data-wrapper {
    position: absolute;
    top: -55px;
    bottom: 0;
    width: 100%;
    margin: auto;
    z-index: 9;
    height: 20px;
    text-align: center;
    font-size: 12px;
}
.pie-chart-Wrapper .no-data-wrapper.no-data-series-wrapper {
    top: 0;
}
.assignmentCalenderCover .DayPicker-Month {
    border-collapse: separate !important;
    max-width: 300px;
}
.assignmentCalenderCover .ant-card-head{
    padding: 0;
    min-height: 0;
    border: 0;
    font-weight: 600;
    font-size: 14px;
}
.assignmentCalenderCover  .DayPicker-Month{
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100%;
}
.assignmentCalenderCover   .DayPicker-NavButton{
    right: 0.3em;
}
.assignmentCalenderCover  .DayPicker{
    width: 100%;
}

.assignmentCalenderCover  .ant-card-head-title{
    padding: 0 7px;
}
.assignmentCalenderCover   .ant-card-head-title{
    text-align: left;
}

.assignmentCalenderCover .ant-list-item{
    text-align: left;
    padding: 0;
    padding-bottom: 8px;
    border: 0;
}
.assignmentCalenderCover .ant-list-item > div{
    padding: 10px ;
    border: 1px solid #eee;
    border-radius: 4px;
    line-height: 20px;
}

.assignmentCalenderCover .ant-list-items{
    overflow: auto;
    max-height: 300px;
    margin: 0 -20px;
    padding: 0 19px;
    padding-bottom: 20px;
    margin-bottom: -20px;
}

.assignmentCard .ant-card-extra{
    padding:  0;
}
.assignmentCard  .ant-card-head-title{
    font-size: 18px;
    padding-left: 30px;
}
.assignmentCard   .ant-card-extra{
    position: absolute;
    left: -5px;
    top: -2px;
}
.assignmentCard   .ant-card-extra .ant-btn{
    border: 0;
    padding: 0 10px ;
}
.assignmentCard .ant-card-head-wrapper{
    margin-bottom: 15px;
}



.table-cell-meta .meta-title{
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    text-transform: capitalize;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.tablePieChartCover{
    overflow: hidden;
}
.tablePieChartCover > div{
    margin: -14px 0;
    margin-left: -20px !important;
}

.outcome-chart > div{
    margin: -14px 0;
    margin-left: 0 !important;
}
.cohortChart .tablePieChartCover > div{
    margin-left: 0px !important;
}
.tablePieChartCover > div tspan {
    font-weight: 600;
}
.tabeSolidGauge .highcharts-axis-labels.highcharts-yaxis-labels{
    display:none;
}
.tabeSolidGauge  .largeHeading{

}

.side-charts h5{
    margin-bottom: -20px;
    position: relative;
    z-index: 100;
}

.activatingConfig{
    line-height: 31px;
}
.activatingConfig .ant-typography {
    line-height: 31px !important;
    margin: 0 !important;
    display: inline-block !important;
    padding: 0 4px;
}
.activatingConfig .ant-form-item-label{
    display: inline !important;
}
.activatingConfig  .ant-form-item-control{
    display: inline !important;
}


.outcomeState{
    position: absolute;
    right: 0;
    top: -12px;
}

.accHeading{
    margin: 0px -1px;
    margin-top: -11px;
    margin-bottom: -9px;
    padding: 9px 0;
    padding-top: 15px;
    background: #fff;
    display: block;
    position: relative;
    top: 0px;
    border-bottom: 1px solid #dcdcdc;
}

.outcome-label{
    padding: 3px 30px;
    font-size: 14px;
    color: #fff;
    padding-bottom: 5px;
    margin-bottom: -4px;
    border-radius: 5px;
    border: 0;
}


.profileCard{
    background: #3870FF;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 12px;
    padding: 26px 20px;
}
.profileCard .ant-avatar{
    width: 75px;
    height: 75px;
    padding: 4px;
    border: 6px solid #6C95FF;
    position: relative;
    background: #fff;
}

.profileCard h2{
    font-size: 22px;
    line-height: 25px;
    color: #fff;
    margin: 5px 0;
    font-weight: 600;
}
.profileCard h4{
    margin: 0;
    font-size: 14px;
    color: #fff;
}

.profileCard .ant-avatar::before{
    content: "";
    width: 67px;
    height: 67px;
    border: 6px solid #9FBAFF;
    position: absolute;
    left: -2px;
    top: -2px;
    border-radius: 50%;
}

.studentDetailTable  .table-cell-meta .meta-title{

}

.studentDetailTable   .table-cell-meta{
    min-width: 150px;
}

.meta-subtitle{
    line-height: 15px;
    font-size: 13px;
    display: block;
}

.table-cell-meta .ant-avatar{
    border-radius: 4px;
    width: 40px;
    height: 40px;
    position: absolute;
    left: 0;
    top: 0;
}
.tableCoverCard .ant-card-head-title{
    padding: 0;
    font-size: 20px;
}
.tableCoverCard  .ant-card-extra{
    padding: 0;
}
.tableCoverCard   .ant-form-item{
    margin: 0;
}
.tableCoverCard   .ant-card-head-wrapper{
    padding: 0 0 15px 0;
}
.tableCoverCard   .ant-card-head{
    padding: 0;
    border: 0;
}
.tableCoverCard .table-stat-cell {
    display: inline-block;
    min-width: 50px;
    padding-right: 15px;
}
.tableCoverCard  .stat{
    font-size: 25px;
    line-height: 30px;
    font-weight: 600;
}
.tableCoverCard  .stat-description{
    font-size: 13px;
    font-weight: 600;
}
.tableCoverCard .table-stat-cell .stat {
    font-size: 20px;
}


.ant-card.tableCoverCard {
    border: 0 !important;
}
.tableCoverCard  .ant-card-body{
    padding: 0;
}
.tableCoverCard   .ant-table-row:hover > td{
    background: #fff !important;
}
.tableCoverCard .ant-table-tbody > tr > td {
    
}

.tableCoverCard.studentDetailTable .ant-table-thead th .ant-table-column-sorters{
    padding: 10px 15px !important;  
} 

.ant-table-cell .ant-tag{
    border-radius: 20px;
    padding: 0px 15px;
}
.ant-tag.risk-indicators {
    width: 80px;
    text-align: center;
}
.ant-tag.module-state {
    text-align: center;
}

.ant-col{
    width: 100%;
}


/* Side bar */

.searchFilter{
    padding: 15px;
    background: #fff;
    border-bottom: 1px solid #eee;
}
.searchFilter.no-search{
    border: 0;
    padding-bottom: 5px;
}
.searchFilter.no-search h5{
    margin: 0;
}
.searchFilter .ant-form-item{
    margin: 0;
}

.menuScroller{
    overflow: auto;
    overflow-x: hidden;
}

.course-listing  .menuScroller{
    height: calc(100vh - 370px);
}
.with-pagination.course-listing  .menuScroller{
    height: calc(100vh - 425px);
}


/* single column Layout */

.singleColumnLayout{
    margin: 0 auto;
    max-width: 1300px;
    padding: 70px 10px 15px 10px;
    min-height: 100vh;
    display: block;
    width: 100%;
}
.studentDetails .textWidget{
    margin: 0;
}
.studentDetails .textWidget .valueContainer{
    font-size: 30px;
    padding-left: 60px;
}
.studentDetails .textWidget .valueContainer > div:first-child{
    line-height: 25px !important;
}
.studentDetails .textWidget .valueContainer > div span{
    display: inline-block;
}
.studentDetails .textWidget  .in-iconCover{
    margin-bottom: 10px;
}

.in-smallContainer{
    max-width: 800px;
    margin: 0 auto;
    width: 95%;
    padding-top: 20px;
}



/* custom cards */

.in-CustomCard{
    padding: 0;
    border: 0;
}
.in-CustomCard .ant-card-body{
    padding: 0;
}


/* settings page */

.SettingSecCard{
    border-radius: 4px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.24);
    margin-bottom: 25px;
}

.SettingSecCard .ant-collapse-header{
    font-size: 16px;
    font-weight: 600;
}


.SettingSecCard .ant-card-head-title{
    font-weight: 600;
    font-size: 18px;
}
.SettingSecCard  .ant-form-item-label > label{
    line-height: 18px !important;
    height: auto;
}
.activatingConfig .ant-form-item{
    margin: 0 !important;
    display: flex !important;
    flex-direction: row !important;
}
.activatingConfig.MobileBlock{
    text-align: left;
    display: none;
}

.activatingConfig.MobileBlock .ant-form-item-control{
    max-width: 100px;
}

.activatingConfig.MobileBlock .ant-col{
    display: block;
}
.activatingConfig.MobileBlock .makeInline > div{
    display: inline-block !important;
}
.activatingConfig.MobileBlock .makeInline.indent1{
    padding-left: 20px;
}
.activatingConfig.MobileBlock .withRadio{
    padding-left: 40px;
    position: relative;
}

.activatingConfig.MobileBlock .withRadio .floatingRadio{
    position: absolute;
    left: 0;
    top: 32px;
}

.activatingConfig .ant-form-item-control-input-content{
    display: flex;
}
.activatingConfig .ant-col {
    display: inline-flex;
    justify-content: center;

}
.activatingConfig .ant-typography {
    font-size: 14px !important;
    display: flex;
    align-content: center;
}
.activatingConfig .ant-form-item-control-input-content .quiz-assignment{
    display: flex !important;
    flex-direction: row !important;

}
.activatingConfig .ant-form-item-label {
    text-align: left;
}
.customCheckCover{
    position: relative;
    padding: 15px 15px 15px 70px;
    margin-bottom: -1px;
    border: 1px solid #eee;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
}
.customCheckCover .text{
    margin-left: 5px;
    width: 100%;
}
.customCheckCover .anticon{
    margin-right: 5px;
    margin-top: 5px;
}
.customCheckCover .firstItem{
    margin: 0 !important;
    position: absolute;
    left: 14px;
    top: 10px;
}
.customCheckCover .lastItem{
    margin: 0 !important;
    /* position: absolute;
    right: 12px;
    top: 11px; */
    width: 70px
}
.headingSettings{
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: 600;
}

.cardFooter{
    border-top: 1px solid #eee;
    margin: 0 -25px;
    padding: 15px 25px 0 25px;
    margin-bottom: -5px;
    text-align: right;
    margin-top: 20px;
}
.cardFooter .ant-btn{
    margin-left: 10px;
    border-radius: 4px;
}
.mapping-container{
    display: flex;
    justify-content: space-between;
}
.mapping-color-cover{
    float: unset !important;
}

.fromToCover{
    position: relative;
    width: 35%;
    display: inline-block;
}
.fromToCover > span{
    position: absolute;
    font-size: 11px;
    left: 50%;
    top:8px;
    margin-left: -20px;
}
.fromToCover .ant-form-item{
    width: 50%;
    display: inline-block;
}
.fromToCover .ant-form-item:first-child{
    padding-right: 35px;
}
.fromToCover .ant-form-item:last-child{
    padding-left: 35px;
}
.colorConfigRow{
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 4px;
    padding-right: 40px;
    margin-bottom: -1px;
    position: relative;
}
.colorConfigRow.colorRowHeading{
    border: 0;
    padding: 0 10px;
    padding-right: 40px;
    font-weight: 600;
    padding-bottom: 10px;
}
.btnCover{
    text-align: right;
    padding: 10px 0;
}

.colorConfigRow.colorRowHeading .fromToCover{
    margin-left: -10px;
}
.colorConfigRow .ant-form-item{
    margin: 0 !important;
}
.gradeColorCover{
    width: 35%;
    float: right;
}
.gradeColorCover .ant-form-item{
    width: 50%;
    display: inline-block;
    padding-right: 10px;
}

.colorConfigRow  .anticon-delete{
    position: absolute;
    right: 15px;
    top: 16px;
    color: rgb(218, 18, 18);
    font-size: 18px;
}
.staticFields{
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 4px;
    margin-bottom: -1px;
    position: relative;
}
.staticFields .ant-form-item{
    margin: 0 !important;
}
.staticFields .labelText{
    padding: 5px 0;
    font-weight: 600;
    display: inline-block;
    width: 60%;
}
.staticFields

.staticFields .gradeColorCover .ant-form-item{
    margin: 0;
    padding: 0;
}

.in-loader{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.877);
    z-index: 9999;
}
.in-loader .anticon-loading{
    margin-left: -14px;
    margin-top: -14px;
    position: absolute;
    left: 50%;
    top: 50%;
}
.in-loader-text{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 48.5%;
    top: 53%;
    background: rgba(255, 255, 255, 0.877);
    z-index: 9999;
}
.saml-verify-user-result{
    width: 50%;
    margin-left: 25%;
    border: 1px solid black;
    margin-top: 14%;
}

.in-sidebarLayout .in-loader{
    z-index: 60;
}

.assignments-tab-view .ant-tabs-centered > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']),
.assignments-tab-view .ant-tabs-centered > div > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']) {
    justify-content: flex-start;
}

.assignments-tab-view  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #4A44E5;
}

.assignments-tab-view .ant-tabs-ink-bar {
    background: #4A44E5;
    height: 3px !important;
}

.assignments-tab-view .ant-tabs-tab:hover {
    color: #4A44E5;
}

.main-title-tab {
    font-weight: 600;
    font-size: 18px;
    text-transform: capitalize;
}

.assignments-tab-view .ant-tabs-nav-wrap {
    padding-left: 15px;
}

.assignments-tab-view .ant-tabs-content-holder {
    padding: 0 15px 15px 15px;
}

.assignments-tab-view .tab-name {
    margin-bottom: 15px;
}

.assignment-drawer .ant-drawer-body {
    padding: 0;
}

.assignment-drawer   .ant-drawer-close {
    width: 40px;
    padding: 16px 0 !important;
}

.assignment-drawer .site-description-item-profile-p {
    padding-left: 55px !important;
}
.assignment-drawer .site-description-item-profile-p:before{
    left: 40px !important;
}

.assignment-drawer .site-description-item-profile-p {
    padding-left: 74px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #f0f0f0;
    position: relative;
    margin-bottom: 0;
}

.assignment-drawer .site-description-item-profile-p:before {
    position: absolute;
    height: 100%;
    content: '';
    width: 1px;
    left: 54px;
    top: 0;
    background: #f0f0f0;
}

.assignment-drawer .ant-drawer-close {
    top: -3px !important;
    left: 0 !important;
    margin-right: 0;
    padding-right: 20px;
}

.assignment-drawer .ant-drawer-close:focus {
    outline: none;
}


/* Ui fixes */
.ant-tabs.customTabs .ant-tabs-nav{
    margin-top: -12px;
}

.ant-tabs.customTabs  .ant-tabs-extra-content  .ant-form-item{
    margin: 0;
    padding: 12px 0 12px 0;
}

.ant-tabs.customTabs  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #4A44E5;
    font-weight: 500;
}
.ant-tabs-ink-bar{
    background: #4A44E5;
}
.ant-tabs.customTabs  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #4A44E5;
}
/* Fix height issues */

.assignmentCover{
    min-height: 421px;
    flex-grow: 1;
}
.courseComparison{
    min-height: 847px;
    flex-grow: 1;
}

.activeMenu .in-sidebarLayout .ant-layout-sider{
    left: 0 !important;
}

.engagement-popover .segment {
    margin: 0px 5px;
}

.engagement-popover .fraction {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    letter-spacing: 0.001em;
    text-align: center;
}
.engagement-popover .fraction span.top {
    display: block;
    padding: 0.1em;
    width: 100%;
}
.engagement-popover .fraction span.bottom {
    border-top: thin solid black;
    width: 100%;
}

.risk-conditions-amp {
    font-size: 11px;
    display: inline !important;
    margin: 0 1.2%;
    flex: none;
    width: auto;
    top:8px;
}

.course-term-filter{
    position: fixed;
}

/* admin center styles */
.adminHeadingCover{
    padding: 20px 0 0 0;
}
.adminHeadingCover .sale-admin-filters{
    margin-right: -13px;
}
.stat-view-card{
    border-radius: 4px;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.068);
}
.stat-view-card .ant-card-body{
    padding: 5px 20px 14px 20px;
}
.stat-view-card  .ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav{

}
.stat-view-card .ant-table-tbody > tr > td {
    padding: 2px 10px !important;
}
.stat-view-card .ant-table-thead > tr > th{
    padding: 5px 8px;
    padding-left: 10px;
    background: #4A44E5 !important;
    color: #fff !important;
}
.minStatCard{

}
.stat-view-card .img-fluid{
    padding: 0;
}
.stat-view-card .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #fff;
}
.consumerButton{
    margin-right: 15px !important;
}
.statsDashFilter{
    padding-top: 10px;
}
.statsDashFilter .filter-div{
    padding: 10px 0 !important;
    margin-right: -14px;
}
.pageViewsWidget{
    padding-left: 140px;
    position: relative;
}
.stat-top-card-section{
    position:absolute;
    left: 0;
    top: 0;
    width: 120px;
}
.stat-top-card-section h2{
    margin: 0 !important;
}
.stat-top-card-section h5{
    margin: 0 !important;
    line-height: 1.2 !important;
    font-size: 14px;
}

.stat-card {

}
.statRow .ant-card{
    background: #FFFFFF;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
}
.stat-top-card-section  .stat-top-card-container {
    margin-bottom: 10px;
    border: 1px solid #C1C7F0;
    border-radius: 4px;
}
.minStatCard  thead > tr > th {
    padding: 5px 10px;
    min-width: 100px;
}
.minStatCard   .ant-table-tbody > tr > td {
    padding: 5px 10px;
}

.minStatCard  .ant-card-body{
    padding: 22px 24px;
}

.minStatCard  .main-card-header{
    margin-bottom: 10px;
}

.statRow{
    padding-bottom: 16px;
}

/* overides */

.customPopover{

    max-width: 300px; 

    width: 90%;

}

/* global settings styles */

.loader-wrapper{
    left: 0;
}
.octo-confirm-popup{
    width: 500px !important;
}
.octo-confirm-popup .button-section{
    display: block;
    text-align: right;
    margin: -7px -24px;
    padding: 20px 20px 0 0;
    margin-top: 20px;
    border-top: 1px solid #eee;
}

.octo-confirm-popup .ant-radio-wrapper .octo-error-messege{
    margin-bottom: -20px;
    white-space: normal !important; 
    padding-left: 20px;
    position: relative;
    color: #d81f1f;
}
.octo-confirm-popup .ant-radio-wrapper .octo-error-messege .anticon{
    position: absolute;
    left: 1px;
    top: 5px;
}

.ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover { 
    color: #4A44E5 !important;
}
.ant-menu-submenu-selected {
    color: #4A44E5 !important;
}
.ant-btn:hover, .ant-btn:focus {
    color: #4A44E5;
    border-color: #4A44E5 !important;
}
.ant-btn-primary:hover, .ant-btn-primary:focus {
    background: #4A44E5 !important;
    border-color: #4A44E5 !important;
    color: #fff;
}

.ant-menu-item-disabled, .ant-menu-item-disabled:hover {
    color:#000000D9 !important;
    background: #FFFFFF !important;
    cursor: not-allowed
}

.select-menu-text {
    max-width: 80%;
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
    letter-spacing: normal;
    margin-bottom: -5px !important;
    white-space: nowrap;
}

.select-disable-icon {
    cursor: pointer !important;
    margin-top: 14px;
    right: 5px;
    position: absolute;
}


/* table filter UI */

.ant-table-filter-dropdown{
    min-height: 0;
}

.ant-table-filter-dropdown  .ant-dropdown-custom > .table-filter-dropdown-row > div{
   
}

.dropdown-scroll{
    margin: 5px -10px 0 -10px;
    max-height: 150px;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 10px;
    border-top: 1px solid #eee;
}

.ant-table-filter-dropdown .table-filter-search-row{
    margin-bottom: 5px;
}
.ant-table-filter-dropdown .table-select-all{
    padding: 2px 10px !important;
    margin: 0 -10px !important; 
    display: block;
    cursor: pointer;
}

.ant-table-filter-dropdown  .table-filter-dropdown-item{
    padding: 2px 10px !important;
    margin: 0 -10px; 
    display: block;
    cursor: pointer;
}
.ant-table-filter-dropdown  .table-filter-dropdown-item:hover, .ant-table-filter-dropdown .table-select-all:hover{
    background: #eee;
}

.ant-table-filter-dropdown  .ant-dropdown-custom > .table-filter-search-row > .search-input {
    margin: 0 !important;
}

.ant-table-filter-dropdown .ant-dropdown-custom  {
    margin: 0 !important;
    padding: 10px 10px 0 10px;
    min-height: 90px !important;
}
.ant-table-filter-dropdown .ant-dropdown-custom  .table-filter-dropdown-btn-section{
    margin: 0 -10px !important;
    padding: 8px 10px;
}

.ant-table-filter-dropdown .ant-dropdown-custom  .table-filter-dropdown-btn-section .ant-btn{
    margin: 0;
}
.ant-dropdown-custom > .tabel-filter-no-data{
    margin: 0;
    padding: 10px 0;
    font-size: 14px !important;
    font-weight: 400 !important;
}


@media only screen and (max-width: 1422px) {
    .chartRiskCondition .no-data-wrapper {
        top: -66px;
    }
}

/* Credential management */
.credentialManagement > .ant-card > .ant-card-body{
    padding: 0;
}

.credentialManagement > .ant-card {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
}

.credentialManagement .ant-tabs-nav{
    margin: 0;
}

.credentialManagement .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #4A44E5;
}

.credentialManagement .userInfoCard{
    padding: 5px 10px;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    margin-bottom: 15px;
}

.credentialManagement .userInfoCard  h6{
    margin: 0;
    font-weight: 600;
}

.credentialManagement .userInfoCard p{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.credentialManagement .infoRow{
    display: flex;
}

.credentialManagement .infoRow p{
    margin: 0 !important;
    padding-right: 25px; 
}

.credentialManagement .actionButtons{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: right;
}

.credentialManagement .actionButtons .ant-btn{
    margin-left: 10px;
}

.credentialManagement .seqSteps{
    padding: 20px 30px;
    padding-left: 60px;
    position: relative;
}

.credentialManagement .seqSteps:first-child:before{
    content: "";
    width: 4px;
    background: #4A44E5;
    height: 100%;
    position: absolute;
    top: 54px;
    left: 35px;
}

.addDeveloperKey .ant-form-item-label{
    text-align: left;
}

.addDeveloperKey .ant-form-item-label label{
    margin: 0;
}

.instruction-card .instruction-card-icon .anticon svg{
    color: #4A44E5;
}

.credentialManagement .statusChip > div.status-active{
    background: #0d9732;
}

.credentialManagement .statusChip > div{
    padding: 2px 15px;
    background: #F5222D;
    color: #fff;
    border-radius: 15px;
}

.credentialManagement .statusChip > div.status-active{
    background: #0d9732;
}

.credentialManagement .statusChip p{
    margin: 0 !important;
}

.credentialManagement .ant-btn-background-ghost.ant-btn-primary{
    color: #4A44E5;
    border-color: #4A44E5;
}

.credentialManagement .ant-btn-background-ghost.ant-btn-primary:hover, .credentialManagement .ant-btn-background-ghost.ant-btn-primary:focus{
    color: #6661ef;
    border-color: #6661ef;
    background: #e7e6ff !important;
}

.credentialManagement .ant-btn-background-ghost.ant-btn-primary.active{
    color: #6661ef;
    border-color: #6661ef;
    background: #e7e6ff !important;
}

.credentialManagement .ant-btn-background-ghost.ant-btn-dangerous:hover, .credentialManagement .ant-btn-background-ghost.ant-btn-dangerous:focus{
    color: #F5222D;
    border-color: #F5222D !important;
    background: #ffdedf !important;
}

.ant-form-item-control-input-content{
    position: relative;
}

.tokenHelpLink{
    text-align: right;
    position: absolute;
    top: -30px;
    right: 0;
    color: #4A44E5;
}

.tokenHelpLink:hover{
    color: #4A44E5;
}

.instruction-card{
    padding-left: 15px;
    position: relative;
}

.instruction-card .instruction-card-icon{
    position: absolute;
    left: 0;
    top: -2px;
}

.instruction-card .instruction-card-icon .anticon{
    font-size: 20px;
}

.credentialManagement .seqSteps .seqHeader{
    padding-left: 45px;
    position: relative;
    margin-bottom: 15px;
    margin-left: -40px;
}

.credentialManagement .seqSteps .seqHeader h5{
    line-height: 34px;
    margin: 0;
}

.credentialManagement .seqSteps .seqHeader .sequenceNumber{
    background: #4A44E5;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 34px;
    color: #fff;
    font-size: 20px;
    border-radius: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.credentialManagement  .ant-tabs-extra-content{
    padding: 12px;
}

.credentialManagement .ant-tabs-tab:hover{
    color: #4A44E5;
}

.credentialManagement .ant-tabs-tabpane.devKeyManage{
    padding: 0;
}

.credentialManagement .ant-tabs-tabpane{
    padding: 20px;
}

@media only screen and (max-width: 992px) {
    .actionButtons{
        margin-top: 10px;
    }
}

/* credential End */
.credentialManagement .ant-tabs-nav{
    padding-left: 20px;
}

@media only screen and (max-width: 1370px) {
    .in-iconCover{
        height: auto;
        padding-left: 0;
        padding-top: 45px;
    }

}

@media only screen and (max-width: 1200px) {
    .in-iconCover{
        height: auto;
        padding-left: 60px;
        padding-top: 0;
    }
    .courseComparison{
        min-height: auto;
    }
    .studentSumPie > div{
        margin: 0;
        padding-right: 40px;
    }
}

@media only screen and (max-width: 1199px) {
    .chartRiskCondition .no-data-wrapper {
        top: -55px;
    }
}

@media only screen and (max-width: 1156px) {
    .chartRiskCondition .no-data-wrapper {
        top: -65px;
    }
}

@media only screen and (max-width: 1072px) {
    .activatingConfig{
        display: none;
    }
    .activatingConfig.MobileBlock{
        display: block;
    }
}

@media only screen and (max-width: 990px) {
    .chartRiskCondition {
        margin-top: 30px;
    }
    .chartRiskCondition  > div{
        width: 60%;
        margin: 0 auto;
    }
    .chartRiskCondition:after{
        display: none;
    }
    .chartRiskCondition .no-data-wrapper {
        top: -40px;
    }
}

@media only screen and (max-width: 767px) {
    .outcomeState{
        padding-bottom: 20px;
    }
    .outcomeState .grid-switch-text{
        font-size: 12px;
        margin-right: 10px;
        display: inline;
    }
    .ant-breadcrumb{
        display: none;
    }
    .chartRiskCondition  > div{
        width: 60%;
        margin: 0 auto;
    }
    /*.in-sidebarLayout .ant-layout-sider{*/
    /*    left: -300px;*/
    /*}*/
    .in-sidebarLayout{
        padding-left: 0;
    }
    .withMenu.in-header{
        padding-left: 30px;
    }
    .withMenu.in-header .mobileNav{
        position: absolute;
        left: 0;
        top: 7px;
        padding: 10px;
        display: block;
    }

    .customCheckCover .lastItem{
        width: 120px
    }

    .outcomeState{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .fromToCover{
        width: 45%;
    }
    .gradeColorCover{
        width: 55%;
    }

    .staticFields .labelText{
        width: 40%;
    }


}

@media only screen and (max-width: 513px) {
    .chartRiskCondition .no-data-wrapper {
        top: -55px;
    }
}

.successMessageDiv {
    text-align: center;
    padding: 100px 0 0 0 ;
}

.successMessageHeading {
    font-size: 38px;
    line-height: 46px;
    font-weight: 600;
}

.successMessagepara {
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    padding: 5px 5px 20px 5px;
}

.successMessageIcon {
    width: 80px;
    height: 80px;
    margin-bottom: 30px;
}

.due-date-column > div {
    margin-left: 20px;
}

.ant-dropdown-custom {
    max-width: 180px;
    min-height: 180px;
    margin-left: 10px;
}

.ant-dropdown-custom > .tabel-filter-no-data {
    position: absolute;
    top: 50%;
    left: 36%;
    letter-spacing: normal;
    font-size: 16px;
    font-weight: 600;
    color: #d9d9d9;
}

.ant-dropdown-custom > .table-filter-dropdown-row > div {
    margin-top: 25px;
    margin-bottom: 10px;
    width:100%
}

.ant-dropdown-custom > .table-filter-dropdown-row > div:hover {
   cursor: pointer;
   background-color: #f0f0f0;
}

.ant-dropdown-custom > .table-filter-dropdown-item {
    display: flex;
    padding-left: 10px;
    margin-bottom: 5px;
    cursor: pointer;
}

.ant-dropdown-custom > .table-filter-dropdown-item:hover {
    background-color: #f0f0f0;
}

.ant-dropdown-custom > .table-filter-dropdown-item > p {
    margin-left: 8px;
    margin-top: 1px;
    margin-bottom: 0px;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    letter-spacing: normal;
}

.ant-dropdown-custom > .table-filter-dropdown-btn-section {
    margin-top: 10px;
    margin-left: -10px;
    border-top: 1px solid #f0f0f0;
    padding-bottom: 10px;
}

.ant-dropdown-custom > .table-filter-dropdown-btn-section > button {
    margin-top: 10px;
    margin-left: 5px;
}

.ant-dropdown-custom > .table-filter-dropdown-btn-section > .filterApply {
    position: absolute;
    right: 10px;
}

.ant-dropdown-custom > .table-filter-search-row > .search-input {
    margin-top: 10px;
    margin-right: 10px;
    border-radius: 5px;
}


.task-filter-group > .ant-row > .ant-col {
    width: auto;
}

.task-filter-select-all {
    margin-bottom: 10px;
    padding-left: 12px;
    margin-top: 5px;
    cursor: pointer;
}

.task-filter-select-all > span {
    margin-left: 10px;
}

.task-filter-custom-tag {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 0px;
    letter-spacing: normal;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.taskFilterInTab {
    justify-content: end;
    display: flex;
    margin-right: 8px;
    margin-bottom: 0;
    height: 33px;
    position: absolute;
    right: 0;
    top: -9px;
}

.customTabs{
    min-height: 400px;
}

.customTabs .ant-tabs-nav-wrap{
    height: 56px;
}

.innovative-help-center > span {
    cursor: pointer;
}

.innovative-help-center > a {
    color: #313131;
    letter-spacing: normal;
    cursor: pointer;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.feedback_success_icon_row {
    justify-content: center;
    margin-bottom: 20px;
}

.feedback_success_text_row {
    justify-content: center;
}

.feedback_success_text_row > p {
    text-align: center;
    letter-spacing: normal;
    max-width: 280px;
}

.help-feedback-modal-overlay {
    background-color: #FFFFFF;
    opacity: 1;
}

.help-feedback-modal-overlay > div > div {
    max-height: 100% !important;
}

.help-feedback-modal-overlay > div > div > span {
    font-size: 34px;
    color: #5849f1;
    position: absolute;
    top: 40% !important;
}

.in-side-chart-no-data {
    padding-top: 60px;
    padding-bottom: 25px;
    background: #fff;
    text-align: center;
    max-width: 400px;
    min-height: 200px;
    margin: 0 auto;
    border: 1px solid rgb(219, 214, 214);
    border-radius: 8px;
}

.in-side-chart-no-data h3 {
    margin: 15px 0 0;
    font-weight: 600;
    font-size: 15px;
    text-align: center;
}
/* Dashboard Maintenance */
.tagList {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.tagList .tag-user-name-text{
    padding-right: 10px;
    padding: 5px;
}

.tagList .ant-tag{
    margin-top: 5px;
    margin-right: 5px;
    background-color: #4A44E5;
    border-radius: 20px;
    color: #ffffff;
    font-size: 13px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.maintenanceModePopupText{
    margin-top: 10px;
}

.tagList .ant-tag span{
    padding-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center; 
    color: #ffffff;
}

/* module progress */

.moduleProgressPopup .ant-modal-title{
    font-weight: 600;  
}

.moduleProgressPopup .loadingCover{
    text-align: center;
}

.moduleProgressPopup .moduleSumCard{
    margin-bottom: 15px;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
}
.moduleProgressPopup .moduleSumCard .ant-card-body{
    padding: 15px 20px;
}
.moduleProgressPopup .ant-modal-body{
    background: #F7F7F7;
}

.moduleProgressPopup .moduleSumCard .numberBlock {
    display: flex;
    align-items: center;
}

.moduleProgressPopup .moduleSumCard .subNumber {
    padding: 0 10px;
    border-radius: 20px;
    background: #0d9732;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    margin-left: 10px;
}

.moduleProgressPopup .moduleSumCard h5{
    margin: 0;
}

.moduleProgressPopup .criteria{
    font-weight: 400;
}
.moduleProgressPopup .ant-modal-body{
    padding: 0;
}
.moduleProgressPopup .scrollCover{
    padding: 15px;
}
.moduleProgressPopup .scroller{
    overflow: auto;
    max-height: 60vh;
}
.state{
    padding: 2px 15px;
    background: #0d9732;
    color: #fff;
    font-weight: 600;
    border-radius: 15px;
    margin-right: 5px;
    text-transform: capitalize;
}

.assignmentRow{
    border-bottom: 1px solid #eee;
    padding: 5px 0;
}
.assignmentRow .anticon{
    font-size: 20px;
    position: relative;
    bottom: -2px;
}
.assignmentRow .anticon.anticon-check-circle{
    color: #0d9732;
}
.assignmentRow .anticon.anticon-close-circle{
    color: #ff0000;
}
.assignmentRow:last-child{
    border: 0;
}
.subjectSegment{
    background: #FFFFFF;
    margin-bottom: 15px;
    padding: 5px;
    padding: 5px;
}
.subjectSegment:last-child{
    margin: 0;
}
.subjectSegment  .ant-card-head{
    padding: 0 10px 0 15px;
}
.subjectSegment  .criteria{ 

}
.subjectSegment  .ant-card-body{
    padding: 10px 15px;
}
.subjectSegment .ant-card-head-title{
    font-size: 18px;
    font-weight: 600;
    padding: 10px 0;
}

.subjectSegment  .ant-card-extra{
    padding: 5px 0;
}

.subjectSegment  .ant-card-head{

}

.loadingCover{
    padding: 15px;
}

.numberBlock{

}

.module-dropdown-card .ant-card-body{
    padding: 0px;
}
.module-dropdown-card .ant-space-item{
    width: auto;
}
.module-dropdown-icon-col{
    padding: 0;
}
.module-dropdown-header-col{
    text-align: center;
    padding: 5px;
}
.module-dropdown-icon{
    padding: 0 16px;
    line-height: 45px;
    border-right: 1px solid #eee;
}
.mainNumber {
    font-size: 25px;
    font-weight: 600;
}
.subNumber {
    padding: 0 10px;
    border-radius: 20px;
    background: #0d9732;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    margin-left: 10px;
}
.gray-module-progress-card{

}
.module-dropdown-card .ant-card-body{
    padding: 0px;
}
.module-dropdown-card .ant-space-item{
    width: auto;
}
.module-dropdown-icon-col{
    width: fit-content; 
    padding: 0 !important;
}
.moduleRow{
    display: flex;
}
.moduleHeadText{
    display: flex;
    justify-content: space-between;
    align-items: center; 
    height: 45px;
    flex-direction: row;
}
.moduleProgressGrid {
    margin: 0 ;
}
.module-no-data{
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    width: 200px;
    line-height: 12px;
}
.module-no-data .anticon {
    margin-right: 5px;
    font-size: 18px;
}
.moduleProgressGrid th{
    padding: 0 !important;
}
.moduleProgressGrid table tr:hover td{
    background: none !important;
}
.moduleProgressGrid table tr:nth-child(even) td{
    background: #f7f7f7 !important;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}
.module-dropdown-header-col{
    text-align: center;
    padding: 0 5px 0 10px !important;
    width: calc(100% - 50px);
}
.module-dropdown-header-col .moduleName{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.moduleSegment{
    padding: 0;
}
.moduleProgressGrid .module-card{
    padding: 0 !important;
}
.moduleProgressGrid .table-cell-meta .meta-title{
    font-size: 16px;
    line-height: 24px;
}
.moduleProgressGrid .ant-table-cell{
    padding: 0;
}
.white-module-progress-card{
    position: relative;
}
.moduleCardList{
    padding: 10px;
    padding-top: 0;
}
.moduleAssignmentList{
    max-height: 170px;
    overflow: auto;
}

.moduleProgressGrid  .moduleSingleItem{
    padding: 10px;
}
.moduleSegment .in-container{
    padding: 0;
}
.moduleSegment .in-section-no-data{
    padding: 20px 5px; 
    margin-top: 5px;
}
.moduleSegment .in-section-no-data img{
    width: 20px;
}
.moduleSegment .subjectSegment{
    padding: 10px 15px;
    overflow: hidden;
    min-height: 147px;
}
.moduleSegment .subjectSegment h5{
    margin: 0;
}
.mainNumber {
    font-size: 25px;
    font-weight: 600;
}
.subNumber {
    padding: 0 10px;
    border-radius: 20px;
    background: #0d9732;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    margin-left: 10px;
}
.gray-module-progress-card{


}
.moduleProgressTable .ant-table-thead > tr > th, 
.moduleProgressTable .ant-table-tbody > tr > td, 
.moduleProgressTable .ant-table tfoot > tr > th, 
.moduleProgressTable .ant-table tfoot > tr > td
{
 padding: 10px 0;
}
/* module progress end */

.studentClasificationTab{
    position: relative;
    min-height: 500px;
}

.cluster-rate-limit-card{
    text-align: center;
    max-width: 600px;
    margin: 40px auto;
}
.cluster-drop-down-padding{
    padding-top: 20px;
}
.cluster-countdown-timer{
    font-size: 22px;
    color: "#4A44E5";
}
.clusterModal {
    padding-top: 100px;
}
.clusterModal .ant-modal-body{
    padding: 0;
}
.clusterModal .ant-modal-body .card-section{
    margin: 0;
}
.clusterModal .ant-modal-body  .card-section{
    padding: 20px;
}
.mainLoadingText{
    position: absolute;
    top: 50%;
    width: 400px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    line-height: 25px;
    margin-left: -200px;
    margin-top: 40px;
    left: 50%;
}

.userCardCluster{
    padding: 12px 20px;
    padding-left: 75px;
    position: relative;
    border-bottom: 1px solid #eee;
}
.userLists > div:last-child .userCardCluster{
    border: 0 !important;
}

.userCardCluster .ant-avatar {
    position: absolute;
    left: 20px;
    top: 10px;
}
.userCardCluster .name-field{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}
.userLists{
    padding: 10px 0;
    max-height: 300px;
    overflow: auto;
}

.userCardCluster .id-field{
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
}

.clusterSection{
    margin-top: -20px;
    padding: 0 10px;
}

.clusterSection .chartContainer {
    width: 100% !important;
}


/* blob loader */



.blobs {
    filter: url("#goo");
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    transform: scale(0.3);
    margin-top: -80px;
  }
  
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes wobble {
    from {
      transform: scale(0.1);
    }
    to {
      transform: scale(4);
    }
  }
  .blob {
    position: absolute;
    background: #111;
    left: 50%;
    top: 50%;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    color: black;
    font-size: 40px;
    border-radius: 100%;
    margin-top: -50px;
    margin-left: -50px;
    transition: all 1s cubic-bezier(0.77, 0, 0.175, 1);
    transform: translate3d(0, 0, 0);
    background: #4A44E5;
    trasnform-origin: 0 0;
    @debug: $x;
    @debug: $x;
    @debug: $x;
    @debug: $x;
    @debug: $x;
    @debug: $x;
    @debug: $x;
    @debug: $x;
    @debug: $x;
    @debug: $x;
    @debug: $x;
    @debug: $x;
    @debug: $x;
    @debug: $x;
    @debug: $x;
    @debug: $x;
    @debug: $x;
  }
  .blob:nth-child(17) {
    transform: scale(1);
    animation: wobble 8s alternate ease-in-out infinite;
  }
  @keyframes move-1 {
    from {
      transform: translate3d(0, 0, 0) scale(0.6);
    }
    to {
      transform: translate3d(155.56349px, 155.56349px, 0) scale(1);
    }
  }
  .blob:nth-child(1) {
    animation: move-1 1s ease-in-out alternate -0.5s infinite;
  }
  @keyframes move-2 {
    from {
      transform: translate3d(0, 0, 0) scale(0.4);
    }
    to {
      transform: translate3d(0px, 220px, 0) scale(0.6);
    }
  }
  .blob:nth-child(2) {
    animation: move-2 1s ease-in-out alternate -1s infinite;
  }
  @keyframes move-3 {
    from {
      transform: translate3d(0, 0, 0) scale(0.1);
    }
    to {
      transform: translate3d(-155.56349px, 155.56349px, 0) scale(0.6);
    }
  }
  .blob:nth-child(3) {
    animation: move-3 2s ease-in-out alternate -1.5s infinite;
  }
  @keyframes move-4 {
    from {
      transform: translate3d(0, 0, 0) scale(0.3);
    }
    to {
      transform: translate3d(-220px, 0px, 0) scale(0.4);
    }
  }
  .blob:nth-child(4) {
    animation: move-4 2s ease-in-out alternate -2s infinite;
  }
  @keyframes move-5 {
    from {
      transform: translate3d(0, 0, 0) scale(0.1);
    }
    to {
      transform: translate3d(-155.56349px, -155.56349px, 0) scale(0.6);
    }
  }
  .blob:nth-child(5) {
    animation: move-5 1s ease-in-out alternate -2.5s infinite;
  }
  @keyframes move-6 {
    from {
      transform: translate3d(0, 0, 0) scale(0.5);
    }
    to {
      transform: translate3d(0px, -220px, 0) scale(0.8);
    }
  }
  .blob:nth-child(6) {
    animation: move-6 2s ease-in-out alternate -3s infinite;
  }
  @keyframes move-7 {
    from {
      transform: translate3d(0, 0, 0) scale(0.3);
    }
    to {
      transform: translate3d(155.56349px, -155.56349px, 0) scale(0.6);
    }
  }
  .blob:nth-child(7) {
    animation: move-7 1s ease-in-out alternate -3.5s infinite;
  }
  @keyframes move-8 {
    from {
      transform: translate3d(0, 0, 0) scale(0.4);
    }
    to {
      transform: translate3d(220px, 0px, 0) scale(0.8);
    }
  }
  .blob:nth-child(8) {
    animation: move-8 2s ease-in-out alternate -4s infinite;
  }
  @keyframes move2-9 {
    from {
      transform: translate3d(0, 0, 0) scale(0.5);
    }
    to {
      transform: translate3d(28.28427px, 28.28427px, 0) scale(0.1);
    }
  }
  .blob:nth-child(9) {
    animation: move2-9 2s ease-in-out alternate -4.28571s infinite;
  }
  @keyframes move2-10 {
    from {
      transform: translate3d(0, 0, 0) scale(1);
    }
    to {
      transform: translate3d(0px, 40px, 0) scale(1.6);
    }
  }
  .blob:nth-child(10) {
    animation: move2-10 2s ease-in-out alternate -4.7619s infinite;
  }
  @keyframes move2-11 {
    from {
      transform: translate3d(0, 0, 0) scale(1);
    }
    to {
      transform: translate3d(-28.28427px, 28.28427px, 0) scale(1.6);
    }
  }
  .blob:nth-child(11) {
    animation: move2-11 2s ease-in-out alternate -5.2381s infinite;
  }
  @keyframes move2-12 {
    from {
      transform: translate3d(0, 0, 0) scale(1);
    }
    to {
      transform: translate3d(-40px, 0px, 0) scale(1.6);
    }
  }
  .blob:nth-child(12) {
    animation: move2-12 2s ease-in-out alternate -5.71429s infinite;
  }
  @keyframes move2-13 {
    from {
      transform: translate3d(0, 0, 0) scale(1);
    }
    to {
      transform: translate3d(-28.28427px, -28.28427px, 0) scale(1.6);
    }
  }
  .blob:nth-child(13) {
    animation: move2-13 2s ease-in-out alternate -6.19048s infinite;
  }
  @keyframes move2-14 {
    from {
      transform: translate3d(0, 0, 0) scale(1);
    }
    to {
      transform: translate3d(0px, -40px, 0) scale(1.6);
    }
  }
  .blob:nth-child(14) {
    animation: move2-14 2s ease-in-out alternate -6.66667s infinite;
  }
  @keyframes move2-15 {
    from {
      transform: translate3d(0, 0, 0) scale(1);
    }
    to {
      transform: translate3d(28.28427px, -28.28427px, 0) scale(1.6);
    }
  }
  .blob:nth-child(15) {
    animation: move2-15 2s ease-in-out alternate -7.14286s infinite;
  }
  @keyframes move2-16 {
    from {
      transform: translate3d(0, 0, 0) scale(1);
    }
    to {
      transform: translate3d(40px, 0px, 0) scale(1.6);
    }
  }
  .blob:nth-child(16) {
    animation: move2-16 2s ease-in-out alternate -7.61905s infinite;
  }
  @keyframes move2-17 {
    from {
      transform: translate3d(0, 0, 0) scale(1);
    }
    to {
      transform: translate3d(28.28427px, 28.28427px, 0) scale(1.6);
    }
  }
  .blob:nth-child(17) {
    animation: move2-17 2s ease-in-out alternate -8.09524s infinite;
  }
  .octopus-blob-pre-loader svg{
      display: block;
      width: 100%;
      height: 100%;
  }
  .octopus-blob-pre-loader {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
  }
  .octopus-blob-pre-loader  .loading-logo{
      position: absolute;
      left: 50%;
      top: 50%;
      width: 35px;
      margin-top: -53px;
      margin-left: -14px;
      left: 50%;
      top: 50%;
      z-index: 1200;
  }
  .octopus-blob-pre-loader .pre-loader .blobs{
      width: 600px;
      height: 600px;
      margin-left: -300px;
      margin-top: -300px;
  }

.octopusAnalyseLoader{
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ffffffc0;
    left: 0;
    top: 0;
}
.user-obi{
    text-align: right;
}
.user-obi .chatThread{
    display: inline-block;
    background: #edecff;
    border: 1px solid #c4c2fb;
}  
.chatLoading{
    text-align: center;
    width: 100%;
    padding: 20px 0;
    background: #eee;
    border: 1px solid  #dadada;
    border-radius: 4px;
    margin-bottom: 20px;
    opacity: 0.6;
}
.chatLoading img{
    width: 60px;
}
  .chatThread .ant-card-body{
    padding: 15px 10px;
}
.chatThread {
    border-radius: 10px;
    margin-bottom: 15px;
    box-shadow: 0 0 10px 0px #00000026;
}

.agent{
    text-align: left;
}
.agent .chatThread {
    width: 100%;
}
.chatInputBox .ant-input-affix-wrapper:focus, .chatInputBox .ant-input-affix-wrapper-focused{
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-color: #8d8d8d;
}
.chatInputBox{
    padding: 16px 15px;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #eee;
    text-align: center;
    box-shadow: 0 0 10px 0px #00000026;
    text-align: center;
    margin-bottom: 5px;
}
.chatSendLoading{
    position: absolute;
    width: 30px;
    height: auto;
    right: 4px;
    top: 4px;
    height: 30px;
    padding: 0 5px;
    background: #fff;
    border-radius: 100%;
}
.chatSendLoading img{
    width: 100%;
    margin-top: -5px;
}
.chatInputDangerContainer .ant-input-affix-wrapper-lg{
    border-style: solid;
    border-color: rgb(255, 0, 0) !important;
    border-radius: 4px;
}
.chatInputDangerContainer .ant-input-suffix{
    color: #4A44E5;
    font-size: 22px;
}
.chatInputDangerContainer .ant-input-suffix{
    color:  rgb(255, 0, 0) !important;
    font-size: 22px;
}
.chatInput input{
    border-radius: 4px;
}
.chatInput .ant-input-suffix{
    color: #4A44E5;
    font-size: 22px;
}
.chatTextValidation{
    color: rgb(255, 0, 0);
    text-align: left;
}
.cluster-info-section-card-text .sub-title{
    margin: 0px;
}
.baseDisclaimer{
    text-align: center;
    margin-top: 10px;
}
.baseDisclaimer a{
    padding-left: 5px;
    color: #4A44E5;
    text-decoration: underline;
}
.chatWrapper{
    padding: 5px;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #eee;
}
.Typewriter{
    font-size: 14px;
    line-height: 20px;
}
.botAvetar{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.botAvetar .ant-avatar{
    margin-right: 5px;
    display: block;
}
.botAvetar .betaChip{
    padding: 0 5px;
    border-radius: 4px;
    background: #FF9100;
    color: #fff;
    font-weight: 600;
    margin-left: 5px;
    font-size: 12px;
    font-weight: 800;
    line-height: 18px;
}

/* user management */
.userManagemetHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}
.userManagemetHeader h3{
    margin: 0;
}
.userManagemetHeader h4{
    line-height: 0;
}
.userNameHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;  
}
.userNameHeader .ant-row.ant-form-item{
    margin: 0;
}
.userNameHeader  h4{
    margin: 0;
    font-weight: 600;
}
.userManagementCard .ant-card-body{
    padding: 15px;
}
.userManagementCard {
    box-shadow: 0px 3px 15px -3px rgba(0,0,0,0.1) !important;
    border-radius: 4px !important;
    overflow: hidden !important;
}
.userManagementCard .ant-btn-background-ghost.ant-btn-primary{
    border-color: #4A44E5;
    color: #4A44E5;;
}
.userManagementCard .ant-btn-background-ghost.ant-btn-primary:hover{
    color: #fff;
}
.userManagementCard tr td{
    border-top: 1px solid #eee;
}
.userManagementCard tr td:last-child{
    padding-right: 0 !important;
}
.userManagementCard tr td:first-child{
    padding-left: 0 !important;
}
.userManagementCard .ant-pagination{
    margin-bottom: 0;
}
.userManagementCard .ant-input{
    height: 45px;
    font-size: 16px;
    border-radius: 6px !important;  
}
.userNameHeader .ant-input-wrapper{
    position: relative;
    width: 400px;
}
.userNameHeader .ant-input-wrapper .ant-input-group-addon{
    background: none !important;
    border: none;
    position: absolute;
    right:  0;
    top: 0;
    height: 100%;
    width: 50px;
    line-height: 51px;
    color: #4A44E5;
    z-index: 100;
}
.userNameHeader .ant-input-wrapper .ant-input-group-addon .anticon{
    color: #4A44E5 !important;
}
.userManagementCard table{
    margin-top: 15px;
    border-bottom: 1px solid #eee;
}
.userManagementCard tr th{
    border-top: 1px solid #eee;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 600;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #4A44E5;
}
.ant-tabs-tab .ant-tabs-tab-btn:hover{
    color: #4A44E5;
}
.courseAccessDrawer .ant-drawer-header .ant-drawer-close{
    position: absolute;
    right: auto;
    left: 0;
    outline: none;
}
.restricted-course-label{
    color: #F5222D;
}
.addUserPopup .ant-form-item-label{
    display: block;
    text-align: left;
}
.addUserPopup .ant-form-item-label label{
    height: auto;
    font-size: 14px;
    margin-bottom: 4px;
}
.addUserPopup .tagList {
    margin: 0;
}
.addUserPopup .tagList .ant-tag{
    padding: 4px 10px;
    margin-top: 10px;
}
.addUserPopup .tagList .ant-tag .anticon{
    color: #fff;
}
.courseAccessDrawer{
    margin-top: 50px;
    height: calc(100vh - 50px) !important;
}
.courseAccessDrawer.ant-drawer-open{
    margin-top: 50px;
    height: calc(100vh - 50px) !important;
}
.courseAccessDrawer .ant-drawer-header {
    padding-left: 50px !important;
}
.courseAccessDrawer .courseSearch .ant-form-item{
    margin-bottom: 5px !important;
}
.courseAccessDrawer .ant-tabs-tab{
    margin-right: 15px;
}
.courseAccessDrawer  .ant-table-thead{
    display: none;
}
.courseAccessDrawer  table td{
    padding: 10px 10px;
    border-bottom: 1px solid #eee !important;
}
.drawerBottomBar{
    text-align: right;
    padding: 15px 0;
    border-top: 1px solid #eee;
}