.student-cluster-settings {
  margin-top: 20px;
  .switch-card {
    border-radius: 8px;
    margin-bottom: 20px;
    .switch-card-text {
      font-size: 16px;
      margin-bottom: 0px;
      font-weight: 600;
    }
    .switch-section {
      margin-top: -24px;
      float: right;
      span {
        font-weight: 600;
        color: #595959
      }
      .status-switch {
        margin: 4px 12px 0px 10px;
      }
    }
  }
  .info-section-card {
    display: flex;
    flex-direction: column;
    .info-section-card-text {
      span[role=img] {
        font-size: 20px;
        margin-top: 5px;
        margin-right: 8px;
        color:#4A44E5;
      }
      .sub-title {
        color:#4A44E5;
      }
      .text-paragraph {
        text-align: justify;
      }
    }
    .info-section-card-video {
      display: flex;
      justify-content: center;
      iframe {
        margin-left: 40px;
        border-radius: 8px;
        border: solid 2px #fff;
      }
    }
  }
}

.cluster-settings-modal {
  .info-row {
    display: flex;
    span[role=img] {
      margin-top: 4px;
      font-size: 20px;
      color: #4A44E5;
    }
    .info-row-description {
      max-width: 280px;
      margin-left: 10px;
    }
  }
}

.student-cluster-setting-loading {
  font-size: 36px;
  color: #4A44E5;
}

.chart-section {
  overflow-x: auto;
  margin-left: 50px;
}

.chart-container {
  .avatar-tag {
    span {
      span {
        opacity: 1 !important;
        position: relative !important;
        left: 0% !important;
      }
    }
  }
  .axis-label {
    stroke: #4f4f4f;
    font-weight: 250;
    letter-spacing: 1px;
    font-size: 14px;
  }

  .axis-line {
    color: #a39f9f;
  }
}

.name-tag {
  display: block;
  height: 20px !important;
  font-size: 12px;
  width: 100px;
  box-shadow: 2px 2px 2px 2px #e6e6e6;
  background-color: #FFF;
  text-align: center;
  span  {
    display: block;
    margin-top: 2px !important;
  }
};

.bottom-row {
  display: flex;
  justify-content: space-between;
  margin-left:  40px;
  margin-bottom: 20px;
  .name-switch {
    margin-top: 10px;
    display: flex;
    p {
      margin-left: 10px;
      margin-top:  -3px;
      letter-spacing: normal;
    }
  }

  .legend-item {
    margin-right: 20px;
    display: flex;
    .legend-circle {
      height: 35px;
      width: 35px;
      background-color: #FFF;
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px;
      &.low-low{
        border: 4px solid #FF5C5C;
      }
      &.high-low{
        border: 4px solid #F2994A;
      }
      &.low-high{
        border: 4px solid #2F80ED;
      }
      &.high-high{
        border: 4px solid #27AE60;
      }
    };
    .legend-label {
      margin-top: -5px;
      p {
        letter-spacing: normal;
        margin-block-start: 0px;
        margin-block-end: 0px;
      }
    }
  }
}


.cluster-modal {
  .ant-modal-close {
    margin-top: -5px;
    color: #000;
  }
}

.cluster-chart-modal-content {
  border-top: 1px solid #b2b2b2;
  margin-top: 20px;
  margin-left: -24px;
  margin-right: -24px;
  max-height: 500px;
  overflow-y: auto;
  .cluster-chart-modal-row {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 24px;
    cursor: pointer;
    .avatar-circle {
      height: 50px;
      width: 50px;
      background-color: #FFF;
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .cluster-chart-name-row {
      margin-top: 12px;
      font-weight: 600;
    }
  }
}

.cluster-chart-modal-singal {
  .header-row {
    height: 100px;
    margin-top: 20px;
    margin-left: -24px;
    margin-right: -24px;
    .name-section {
      padding-top: 20px;
      padding-left: 20px;
      .avatar-outer-circle {
        height: 60px;
        width: 60px;
        border-radius: 50%;
        display: flex;
        margin-right: 10px;
        justify-content: center;
        align-items: center;
        filter: brightness(1.3);
        .avatar-inner-circle {
          height: 52px;
          width: 52px;
          background-color: #FFF;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          filter: brightness(1.5);
          span {
            filter: brightness(0.4);
            margin-top: -1px;
          }
        }
      }
      .name-field-container {
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        .name-field {
          color: #4b4b4b;
          font-size: 16px;
          font-weight: 600;
        }
        .id-field {
          color: #4b4b4b;
          margin-top: -2px;
          font-size: 12px;
        }
      }
    }
  }
  .card-section {
    margin-top: 20px;
    .card-row {
      justify-content: space-around;
      .card-item {
        width: 100%;
        height: 75px;
        border: 1.5px solid #fff;
        border-radius: 5px;
        padding: 8px 15px;
        .card-text {
          display: flex;
          flex-direction: column;
          .card-text-title {
            font-weight: 600;
            color: #fff;
          }
          .card-text-number {
            font-size: 28px;
            color: #fff;
            line-height: 35px;
          }
        }
      }
    }
  }
  .description-section {
    margin: 10px
  }
  .modal-button {
    color:#6244BB;
    border-color: #6244BB;
    margin: 10px;
    width: calc(100% - 20px);
    &:hover {
      color:#6244BB;
      border-color: #6244BB;
    }
  }
}