.stp-activity-filter-btn {
    background-color: #164a90;
    margin-bottom: 5px;
    border-radius: 5px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: relative;
}
.stp-activity-filter .active {
    background-color: #386eb4;
}
.stp-activity-filter-btn:hover {
    background-color: #386eb4;
}
.stp-activity-filter-btn span {
    color : #fff !important;
    font-size: small;
    position: absolute;
    top: 20%;
}
.student-profile-sbmissions-customModal{
    max-width: 1100px !important;
}
.submission-details-table > thead {
    background-color: #1251AE;
}
.submission-details-table > thead > tr > th {
    color: #fff !important;
    font-size: 11.75px !important;
    height: 40px !important;
    text-align: center;
}
.submission-details-table > tbody > tr > td {
    text-align: center;
}
.student-profile .card-header h5{
color: #fff !important;
}
