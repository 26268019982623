.enrolment-table-border{
    border: 1px solid #1d1b1b !important;
}
.enrolment-table > thead {
    background-color: #1251AE;
}
.enrolment-table > thead > tr > th {
    color: #fff !important;
    font-size: 11.75px !important;
    height: 40px !important;
    text-align: center;
}
.enrolment-table > tbody > tr > td {
    text-align: center;
}
.enrolment-summary-row .col-1{
   max-width: max-content; 
}
.enrolment-profile .active-btn {
    color: #fff !important;
    background-color: #1251AE !important;
}
.enrolment-profile .cursor-pointer {
    cursor: pointer;
}
.enrolment-profile .blue-cell {
    color: #fff !important;
    background-color: #1251AE !important;
}
.enrolment-summary-row .summary-header {
    padding: 5px 2px 10px 2px;

}
.enrolment-summary-row .summary-header span {
    font-size: 10px;
    
}
.enrolment-summary-row .card {
    width: max-content;
}

.enrolment-profile .table-bordered td, .enrolment-profile .table-bordered th {
    border-color: #ccc9c9;
}